<template>
    <div>
      <main class="flex-shrink-0 main">
          <!-- {{dataList}} -->
        <div class="main-container p-4">
            <div class="container mb-2">
                <div class="card">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-8">
                                <h6>Add Sale Accounts</h6>
                            </div>
                            
                            <div class="col-4 text-right">
                                <a href="/salewc" style="font-size:16px;">Go to Sale</a>
                            </div>
                        </div>
                        
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="form-group float-label active col-6">
                            <input type="text" class="form-control " placeholder="Account Name" v-model="acName" v-bind:class="[noacName ? 'is-invalid' : 'is-valid']" autofocus>
                            <p class="form-text invalid-feedback" v-show="noacName">Enter a Name/Have its length within 20 characters</p>
                            </div>
                             <div class="form-group float-label active col-6">
                            <input type="text" class="form-control " placeholder="Account Short Name" v-model="acShortName" v-bind:class="[noacShortName ? 'is-invalid' : 'is-valid']">
                            <p class="form-text invalid-feedback" v-show="noacShortName">Enter a Short Name</p>
                            </div>
                            
                        </div>
                        
                        <div class="row">
                            <div class="form-group float-label active col-6">
                                <input type="text" class="form-control" placeholder="Account Phone" v-model="acPhone" v-bind:class="[noacPhone ? 'is-invalid' : 'is-valid']">
                                <p class="form-text invalid-feedback" v-show="noacPhone">Please enter correct Phone Number</p>
                            </div>
                            <div class="form-group float-label active col-6">
                            <input type="text" class="form-control" value="" placeholder="Account E-Mail" v-model="acEmail" v-bind:class="[invalidEmail ? 'is-invalid' : 'is-valid']">
                            <p class="form-text invalid-feedback" v-show="invalidEmail">Please enter correct E-Mail</p>
                            </div>
                           
                            
                        </div>
                        <div class="row">
                            <div class="form-group float-label active col-6">
                                <input type="text" class="form-control" placeholder="Username"  v-model="acUser" v-bind:class="[noacUser ? 'is-invalid' : 'is-valid']">
                                <p class="form-text invalid-feedback" v-show="noacUser">Please enter username</p>
                            </div>
                            <div class="form-group float-label active col-6">
                            <input type="text" class="form-control" value="" placeholder="Password" v-model="acPass" v-bind:class="[noacPass ? 'is-invalid' : 'is-valid']">
                            <p class="form-text invalid-feedback" v-show="noacPass">Please enter a password</p>
                            </div>
                           
                            
                        </div>
                        <div class="row">
                            <div class="form-group float-label active col-6">
                            <label class="text-muted">Bg Color:</label>
                            <input type="color" class="form-control " v-model="acColor">
                            
                            </div>
                            <div class="form-group float-label active col-6">
                            <label class="text-muted">Font Color:</label>
                            <input type="color" class="form-control " v-model="acForeColor">
                            
                            </div>                            
                        </div>                        
                        
                         
                        <div class="form-group position-relative">
                            <button v-on:click="addLedger()" class="btn btn-primary rounded btn-block">Add</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container mb-5 p-3">
                <h6 class="p-2">Accounts</h6>
                <h6 class="p-2" v-if="dataList.length == 0">
                    No Records Found
                </h6>
                <div class="row">
                    <div class="pill" :style="`background-color:${data.acbgcolor};color:${data.acforecolor}`"  v-for="data in dataList" :key="data.id">
                        <span class="text-left">{{data.acname}} <br> <small><b>ShortName: </b>{{data.acshortname}}</small></span>
                        <span class="closebtn" data-toggle="modal" v-on:click="loadItemModal(data.id,data.acname,data.acbgcolor,data.acforecolor)"><b><span class="material-icons">add_circle</span></b></span>
                    </div>
                </div>
                <!-- <div class="card mb-2" v-for="data in dataList" :key="data.id">
                    
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <h6 class="mb-1" :style="`background:${data.acbgcolor};color:${data.acforecolor}`">{{data.acname}}</h6>
                                <p class="text-secondary small"><b>ShortName: </b>{{data.acshortname}} | <b>Phone: </b>{{data.acphone}} | <b>E-Mail: </b>{{data.acemail}}</p>
                                
                                
                            </div>
                           
                            <div class="col-auto">
                                 <a href="javascript:void(0)" class="btn btn-primary rounded btn-md" data-toggle="modal" v-on:click="loadItemModal(data.id,data.acname,data.acbgcolor,data.acforecolor)">  
                                    Attach Items
                                </a>
                                
                            </div>
                            <div class="col-auto">
                                <button v-on:click="editLedger(data.id)"  class="btn btn-info rounded btn-md">Edit</button>
                            </div>
                            <div class="col-auto">
                                <button v-on:click="deleteLedger(data.id)"  class="btn btn-danger rounded btn-md">Delete</button>
                            </div>
                        </div>
                        
                    </div>
                </div> -->
            </div>
        </div>

        <!-- modal ------ -->
            <div v-if="myModel">
            <transition name="model">
            <div class="modal-mask">
            <div class="modal-wrapper">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header">
                    <button type="button"  style="background:none;border:none" @click="myModel=false">
                        <span aria-hidden="true" style="font-size:32px">&times;</span>
                    </button>
                <h4 class="modal-title">Attach Items</h4>
                </div>
                <div class="modal-body">
                    <div class="card ">
                            <div class="card-header">
                                <div v-if="message.length>0" class="alert alert-danger" role="alert">
                                    {{message}}
                                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">x</span>
                                    </button>
                                </div>
                                <small>Note: Select the items and brand with the account</small>
                                <div class="row">
                                    <div class="search-wrapper panel-heading col-sm-12">
                                        <input class="form-control" type="text" v-model="searchQuery" placeholder="Search brand" />
                                    </div>                        
                                </div>
                            </div>
                            <div class="card-body">
                                <div v-if="items.length>0" style="overflow:auto;height:500px;width:100%">
                                    <div class="custom-control custom-switch" v-for="(item,index) in resultQuery" :key="index">
                                        <input type="checkbox" style="width:100%;z-index:99" class="custom-control-input switch-danger" :id="`i${index}`" @click="updateAttachItems(index,item.itemname,item.shortname,item.bgcolor,item.fontcolor,item.seqno)" :checked="`${(item.isselected==1)?'checked':''}`">
                                        <label class="custom-control-label" for="customSwitch3">{{item.itemname}} ({{item.shortname}}) 
                                            <div v-if="spinload[index]" class="spinner-border text-secondary" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div v-if="items.length==0">
                                    <p>No items available to selected</p>
                                </div>
                                
                            </div>
                        </div>    
                </div>
                </div>
            </div>
            </div>
            </div>
            </transition>
        </div>

        <!-- modal ends ---- -->
      </main>








    </div>    
</template>

<script>


export default {
    
    name: "LedgersSale",
    data(){
        return{
            myModel: false,
            token: "",

            acName: "",
            acShortName: "",
            acPhone: "",
            acEmail: "",
            acUser: "",
            acPass: "",
            acColor: "#ffffff",
            acForeColor:"#000000",

            noacName: false,
            noacShortName: false,
            noacPhone: false,
            noacUser: false,
            noacPass: false,
            invalidEmail: false,
            validation: false,

            dataList : [],
            items:[],

            activedate: '',
            selected_acid: '',
            selected_acname: '',
            selected_shortname: '',
            selected_acbgcolor: '',
            selected_acforecolor: '',

            searchQuery: '',

            message:'',
            spinload:[],
            }
        },
        computed: {
            resultQuery(){
                if(this.searchQuery!=''){
                    return this.items.filter((item)=>{
                        return item.shortname.toLowerCase().indexOf(this.searchQuery.toLowerCase()) !== -1;
                        // return item.brand.toLowerCase().includes(this.searchQuery);
                        // console.log(item)
                    })
                }else{
                    return this.items;
                }
            }
        },
        mounted(){
            this.token = localStorage.getItem(this.$ut);
            this.activedate = localStorage.getItem('ad');
                let loader = this.$loading.show({
                    // Optional parameters
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                    onCancel: this.onCancel,
                    color: '#f00',
                    loader: 'spinner', //spinner, bars, dots
                    width: 128,
                    height: 128,
                    opacity: .5,
                });
            this.$df.get(this.$au + "ledgers",{headers : {"Authorization" : `Bearer ${this.token}`}}
            ).then(res =>{
                
                this.dataList = res.data.data
                loader.hide()
            }).catch(err =>{
                        loader.hide()
                        console.log(err)
                    })
        },
        methods: {
            validEmail: function (email) {
                var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            },

             validateForm(){
                this.noacName = false
                this.noacShortName = false
                this.noacPhone = false
                this.noacUser = false
                this.noacPass = false
                this.invalidEmail = false
       
                if((this.acName) && (this.acShortName) && (this.acUser) && (this.acPass)){
                    if(this.acName.length < 1 || this.acName.length > 20){
                        this.noacName = true;
                    }
                    if(this.acUser.length < 1 || this.acUser.length > 20){
                        this.noacUser = true;
                    }
                    if(this.acPass.length < 1 || this.acPass.length > 20){
                        this.noacPass = true;
                    }
                    if(this.acShortName.length < 1 || this.acShortName.length > 20){
                        this.noacShortName = true;
                    }
                   
                    if((this.acEmail.length > 0) && !(this.validEmail(this.acEmail))){
                        this.invalidEmail = true;
                    }
                   
                    if(this.noacName || this.noacPhone || this.noacUser || this.noacPass || this.invalidEmail){
                        return false;
                    }
                    return true;
                }
                this.noacName = true
                this.noacUser = true
                this.noacPass = true
                this.noacShortName = true
                
                return false;
            },
            
            addLedger() {
           
            this.validation = this.validateForm()
            if(this.validation){
                const params = JSON.stringify({
                    acname: this.acName,
                    acshortname: this.acShortName,
                    acphone: this.acPhone,
                    acemail: this.acEmail,
                    acuser: this.acUser,
                    acpass: this.acPass,
                    acbgcolor: this.acColor,
                    acforecolor: this.acForeColor,
                    activedate: this.activedate,
                });
          
                
                this.$confirm('Are you sure you want to add this account?','Confirm:').then(() => {

                let loader = this.$loading.show({
                    // Optional parameters
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                    onCancel: this.onCancel,
                    color: '#f00',
                    loader: 'spinner', //spinner, bars, dots
                    width: 128,
                    height: 128,
                    opacity: .5,
                });





                    this.$df.post(this.$au + "ledgers", params, { headers: {"Authorization" : `Bearer ${this.token}`}
                    }).then(res => {
                        
                        this.acName = "";
                        this.acShortName = "";
                        this.acPhone = "";
                        this.acEmail = "";
                        this.acUser = "";
                        this.acPass = "";
                        
                        this.dataList = res.data.data

                        loader.hide()
                        
                       
                    }).catch(err =>{
                        loader.hide()
                        console.log(err)
                    })
                })
            }else{
                this.$alert('error');
            }
            
        
            },

            updateAttachItems(id,itemname,shortname,bgcolor,fontcolor,seqno){
                this.spinload[id] = true
                let $chkstatus = (document.getElementById('i'+id).checked)?1:0;
                const params = JSON.stringify({
                    chkstatus : $chkstatus,
                    acid : this.selected_acid,
                    itemname : itemname,
                    acname : this.selected_acname,
                    brand : shortname,
                    acbgcolor : this.selected_acbgcolor,
                    acforecolor : this.selected_acforecolor,
                    bgcolor : bgcolor,
                    fontcolor : fontcolor,
                    activedate : this.activedate,
                    seqno: seqno

                })


                

                this.$df.post(this.$au + "dailyattachs",params,{headers : {"Authorization" : `Bearer ${this.token}`}}
                 ).then(res =>{
                    //  console.log(res)
                     if(res.data.status){
                    this.items = res.data.itemdata
                    
                    }else{
                        this.message = res.data.message
                         document.getElementById('i'+id).checked="checked"
                    }
                   this.spinload[id] = false
                 }).catch(err =>{
                        console.log(err)
                        this.spinload[id] = false
                    })
            },

            loadItemModal(acid,acname,acbgcolor,acforecolor){

                this.selected_acid = acid
                this.selected_acname = acname
                this.selected_acbgcolor = acbgcolor
                this.selected_acforecolor = acforecolor


                const params = JSON.stringify({
                    acid: acid,
                });



                let loader = this.$loading.show({
                    // Optional parameters
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                    onCancel: this.onCancel,
                    color: '#f00',
                    loader: 'spinner', //spinner, bars, dots
                    width: 128,
                    height: 128,
                    opacity: .5,
                });

                 this.$df.post(this.$au + "itemsattacheds",params,{headers : {"Authorization" : `Bearer ${this.token}`}}
                 ).then(res =>{
                    this.items = res.data.itemdata
                    this.items.forEach((i,idx) => {this.spinload[idx] = false
       
                    })
                    this.myModel = true;
                    loader.hide()
                 }).catch(err =>{
                    console.log(err)
                    loader.hide()
                 })
            },

            deleteLedger(id){
                 
                this.$confirm('Are you sure you want to delete this Account?','Confirm:').then(() => {
                

                let loader = this.$loading.show({
                    // Optional parameters
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                    onCancel: this.onCancel,
                    color: '#f00',
                    loader: 'spinner', //spinner, bars, dots
                    width: 128,
                    height: 128,
                    opacity: .5,
                });




                     this.$df.delete(this.$au + 'ledgers/' + id, {headers: {"Authorization" : `Bearer ${this.token}`}}
                     ).then(res => {
                         this.dataList = res.data.data
                         loader.hide()
                     }).catch(err =>{
                        console.log(err)
                        loader.hide()
                    })
                })
               
            }
        }
}
</script>

<style>
    .invalidBorder{
        border : 1px solid red;
    }

    .modal-mask {
     position: fixed;
     z-index: 9998;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background-color: rgba(0, 0, 0, .5);
     display: table;
     transition: opacity 1s ease;
   }

   .modal-wrapper {
     display: table-cell;
     vertical-align: middle;
   }
    .pill {
        background-color: #ddd;
        border: none;
        color: black;
        padding: 5px;
        text-align: left;
        text-decoration: none;
        display: inline-block;
        margin: 2px 2px;
        border-radius: 16px;
    }

    .closebtn{
        width:24px;
        height:24px;
        float:right;
    }
    .closebtn:hover {
        color: #ff0000;
        cursor: pointer;
    } 
</style>