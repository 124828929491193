<template>
  <div>
    <!-- Begin page content -->
    <main class="flex-shrink-0 main">
      <!-- {{purchasePymtData}} -->
      <!-- {{paymentData}} -->
      <!-- page content start -->
      <div class="main-container mb-5">
        <div class="container">
          <h6>Working date: {{activedate}}</h6>
          <div class="container mb-2">
                <div class="card">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-6">
                                <h6>Demand Book </h6>
                            </div>
                            <div class="col-6">
                                <span class="float-right text-right" >
                                    <h4>₹ {{totalsale}}</h4>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="form-group float-label active col-11">
                              
                             <select class="form-control" v-model="selectedAccountId" @change="loadEntryTable()">
                                 <option selected disabled>Select Account</option>
                                 <option v-bind:value="{id:ac.id,text:ac.acname,type:ac.actype}" v-for="ac in acData" :key="ac.id">{{ac.acname}}</option>   
                                 <option v-bind:value="{id:999,text:'',type:0}">All</option>
                             </select>
                            </div>
                            <div class="col-1 text-center" style="margin-top:20px;">
                              <a href="/accountssale"> <span class="material-icons" style="font-size:32px">add_circle</span></a>
                            </div>

                        </div>
                        <div class="row" v-if="selectedAccountId.id==0">
                          <div class="col-12">
                            <p>Select the account to view information</p>
                          </div>
                        </div>

                        <div class="row" v-if="itemData.length==0 && selectedAccountId.id>0">
                          <div class="col-12">
                            <p>No data processed for the day.. press process button</p>
                          </div>
                        </div>
                        <!-- sale table starts -->
                        <div class="row"  v-if="selectedAccountId.id!=0 && itemData.length>0">
                          <div class="col-12">
                            <div style="overflow: auto; height: 100%" >
                    
                              <table class="table table-striped table-dark">
                                <tr><td>Demandee</td><td>Item</td><td>Brand</td><td>Qty</td><td>Demand Type</td><td>Action</td></tr>

                                  <tr v-for="(brand) in itemData" :key="brand.id">
                                    <td>{{brand.acname}}</td>
                                    <td >{{brand.itemname}}</td>
                                    <td >{{brand.brand}}</td>
                                    
                                    <td>
                                      <input
                                        type="number"
                                        step="any"
                                        inputmode="numeric"
                                        :id="`q${brand.id}`"
                                        :value="brand.qty"
                                         v-on:blur="updateDemand(brand.id)"
                                        @focus="$event.target.select()"
                                        min="0"
                                        placeholder="0"
                                      />
                                    </td>
                                    <td>
                                      <select class="form-control" :id="brand.id" v-model="demandType[brand.id]" @change="updateDemand(brand.id)">
                                        <option value="liter">Liter</option>
                                        <option value="crates">Crates</option>
                                    </select>
                                    </td>
                                    <!-- <td>
                                      <input
                                        type="number"
                                        step="any"
                                        inputmode="numeric"
                                        :id="`r${brand.id}`"
                                        :value="brand.rate"
                                        @focus="$event.target.select()"
                                        min="0"
                                        placeholder="0"
                                      />
                                    </td> -->

                                   

                                    <!-- <td>
                                      <input
                                        readonly="readonly"
                                        :value ="brand.subtotal"
                                        step="any"
                                      type="number"
                                      />
                                    </td> -->
                                    <td>
                                      <button class="btn btn-primary btn-sm" @click="convertSale(brand.id,brand.itemname,brand.brand)">Convert Sale</button>
                                    </td>
                                  </tr>
                              </table>
                  
                          </div>
                          </div>
                        </div>
                        <!-- sale table ends -->




                    </div>
                </div>
            </div>  
        </div>
      </div>
    </main>
  </div>
</template>

<script>



export default {
  
  name: "Demand",
  data() {
    return {
      opensale: false,
      opensaledetail: false,
      openpayment:false,
      opencrates:false,
      token: "",
      activedate: "",

      demandType:[],

      acData: [],
      itemData: [],
      closingData: [],

      selectedAccountId:"",

      totalsale:0,
      counter:0,
     

    };
  },
  mounted(){
          this.token = localStorage.getItem(this.$ut)
        //   this.uid = JSON.parse(this.dec(localStorage.getItem(this.$ud))).id;
          this.activedate = localStorage.getItem('ad');
          this.loadLedgers();
      
  },

  methods: {

    convertSale(id,itemname,brand){
        let $qty = document.getElementById('q'+id).value; 

        const params = JSON.stringify({
          acid : this.selectedAccountId.id,
          qty: $qty,
          demandtype: this.demandType[id],
          activedate : this.activedate,
          itemname: itemname,
          brand:brand,
        })


      this.$confirm('Are you sure you want convert demand to sale?','Confirm:').then(() =>

            this.$df.post(this.$au + "dailydemandconvert",params,{headers : {"Authorization" : `Bearer ${this.token}`}}
            ).then(res => {
              this.$alert(res.data.message);
            })
      )


    },
    
    updateDemand(id){
        let $qty = document.getElementById('q'+id).value;
        // let $rate = document.getElementById('r'+id).value;

        
        // let $subtotal = (parseFloat($qty) * parseFloat($rate));
 
        const params = JSON.stringify({
          id: id,
          acid : this.selectedAccountId.id,
          acname: this.selectedAccountId.text,
          qty: $qty,
          demandtype: this.demandType[id],
          activedate : this.activedate,
        })


        this.$df.post(this.$au + "dailydemand",params,{headers : {"Authorization" : `Bearer ${this.token}`}}
        ).then(res => {
          console.log(res)
          this.itemData = res.data.data;

        })
        
    },
  

    loadLedgers(){
          this.$df.get(this.$au + "ledgers", {headers : {"Authorization" : `Bearer ${this.token}`}}
          ).then(res =>{
              // this.acData = res;
                this.acData = res.data.data
            })
    },

    loadEntryTable(){
          const params = JSON.stringify({
            activedate: this.activedate,
            acid: this.selectedAccountId.id
          })

          let loader = this.$loading.show({
                // Optional parameters
                container: this.fullPage ? null : this.$refs.formContainer,
                canCancel: false,
                onCancel: this.onCancel,
                color: '#f00',
                loader: 'spinner', //spinner, bars, dots
                width: 128,
                height: 128,
                opacity: .5,
            });


          this.$df.post(this.$au + "dailydemandfetch",params,{headers : {"Authorization" : `Bearer ${this.token}`}}
          ).then(res =>{
            this.itemData = res.data.data
             
            this.itemData.forEach((item)=> {
              this.demandType[item.id] = item.demandtype
            })
            
            loader.hide()

            }).catch(err =>{
                loader.hide()
                console.log(err)
            })




    },
    getClosingData(itemname,brand){
      // return this.closingData.find((item) => item.itemname === itemname && item.brand===brand).qty
      console.log(itemname,brand)

      return 0;
      
    }    
  },
};
</script>

<style scoped>
@media only screen and (max-width: 374px)  {
 
  .btn-custom-size{
    width:200px;
    font-size:14px;
    margin-bottom: 0;
    margin-top:0;
  }
  .slidein {
    max-width: 100vw;
  }
   .table{
    font-size:10px;
    line-height:10px;
 
  }
  .table tr td{
    padding: 1px;
    margin:1px;
  }

  
  small {
  font-size: 10px;
}
}

@media (min-width: 375px) and (max-width: 899px) {
  .btn-custom-size{
    width:200px;
    font-size:14px;
    margin-bottom: 0;
    margin-top:0;
   
  }

  .slidein {
    max-width: 95vw;
  }  
   .table{
    font-size:10px;
    line-height:20px;
 
  }

  .table tr td{
    padding: 2px;
    margin:5px;
  }
  small {
  font-size: 10px;
}
}

@media (min-width: 900px) and (max-width: 1029px) {
  .btn-custom-size{
    width:200px;
    font-size:14px;
    margin-bottom: 0;
    margin-top:0;
   
  }

  .slidein {
    max-width: 80vw;
  }  
   .table{
    font-size:14px;
    line-height:20px;
 
  }

  .table tr td{
    padding: 2px;
    margin:5px;
  }
  small {
  font-size: 10px;
}
}

@media only screen and (min-width: 1030px) and (max-width: 1439px) {
  .btn-custom-size{
    width:200px;
    font-size:15px;
   
  }
  .slidein {
    max-width: 62vw;
    font-size:12px;
  }
  small {
  font-size: 12px;
}

}

@media only screen and (min-width: 1440px) and (max-width: 3840px) {
  .btn-custom-size{
    width:200px;
    font-size:15px;
   
  }
  .slidein {
    max-width: 55vw;
    font-size:12px;
  }
  small {
  font-size: 12px;
}

}

.slidein {
  padding: 2em 3em;
  position: fixed;
  z-index: 100;
  top: 0;
  right: -100%;
  color: #fff;
  background: rgb(1, 38, 80);
  height: 100%;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
  transition: all 0.5s ease-in-out;
}

/* Set positioning back to 0 when toggled opened */
.open {
  right: 0;
}

/* add a close button in case toggle button is hidden on smaller screens */
.close-btn {
  border: none;
  font-weight: bold;
  font-size: 2em;
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.5em;
}

.toggle {
  margin: 1em;
}

button {
  padding: 0.5em 1em;
  font-size: 1.1em;
}




input[type="number"]{
  width: 100%;
}

input[type="text"]{
  width: 100%;
}

.input-readonly{
  color:black;
  background:rgb(217, 255, 1);
  border:black;
}

/* Removes the clear button from date inputs */
input[type="date"]::-webkit-clear-button {
    display: none;
}

/* Removes the spin button */
input[type="date"]::-webkit-inner-spin-button { 
    display: none;
}

/* Always display the drop down caret */
input[type="date"]::-webkit-calendar-picker-indicator {
    color: #2c3e50;
}

/* A few custom styles for date inputs */
input[type="date"] {
    appearance: none;
    -webkit-appearance: none;
    color: #95a5a6;
    font-family: "Helvetica", arial, sans-serif;
    font-size: 18px;
    border:1px solid #ecf0f1;
    background:#ecf0f1;
    padding:5px;
    display: inline-block !important;
    visibility: visible !important;
}

input[type="date"], focus {
    color: #95a5a6;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
}

table {
  table-layout: fixed ;
  width: 100% ;
}
td {
  width: 25% ;
} 


</style>