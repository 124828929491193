<template>
    <div>
    <!-- Begin page content -->
    <main class="flex-shrink-0 main">
        <!-- page content start -->
      
        <div class="main-container mb-5">
            <div class="container">

                <div class="card p-4">
                    <div class="card-header border-0 bg-none">
                        <div class="row">
                            <div class="col align-self-center">
                                <h6 class="mb-0">Daybook working date: {{activedate}}</h6>
                            </div>
                            <div class="col-auto align-self-center">
                                <button class="btn btn-default btn-sm rounded" @click="exportReport()">
                                    Export
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body p-0">
                        <div class="table-responsive">
                            <table class="table table-striped" id="topdf">
                                <thead>
                                    <tr>
                                        <th width="5%">#</th>
                                        <th width="55%">Description</th>
                                        <th width="20%">Amount(+)</th>
                                        <th width="20%">Amount(-)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(data,idx) in dayData" :key="data.id">
                                        <th scope="row">{{idx + 1}}</th>
                                        <td v-if="data.etype == 0"><input type="text" :id="`d${data.id}`" :value="data.description" v-on:blur="updateDayBook(data.id)" @focus="$event.target.select()" style="width:100%"></td>
                                        <td v-if="data.etype == 0"><input type="number" inputmode="numeric" :id="`ai${data.id}`" :value="data.amtin" v-on:blur="updateDayBook(data.id)" @focus="$event.target.select()" min="0" placeholder="0" style="width:100%"></td>
                                        <td v-if="data.etype == 0"><input type="number" inputmode="numeric" :id="`ao${data.id}`" :value="data.amtout" v-on:blur="updateDayBook(data.id)" @focus="$event.target.select()" min="0" placeholder="0" style="width:100%"></td>

                                        <td v-if="data.etype >= 1"><input class="bg-warning" type="text" readonly="readonly" :id="`d${data.id}`" :value="data.description"  style="width:100%;"></td>
                                        <td v-if="data.etype >= 1"><input class="bg-warning" type="number" readonly="readonly" inputmode="numeric" :id="`ai${data.id}`" :value="data.amtin"  style="width:100%;"></td>
                                        <td v-if="data.etype >= 1"><input class="bg-warning" type="number" readonly="readonly" inputmode="numeric" :id="`ao${data.id}`" :value="data.amtout"  style="width:100%;"></td>
                                    </tr>
                                    <tr class="h6">
                                        <td colspan="2">Total:</td>
                                        <td>{{totData[0]['totalamtin']}}</td>
                                        <td>{{totData[0]['totalamtout']}}</td>
                                        
                                    </tr>
                                    <tr class="h6">
                                        
                                        <td colspan="2">Cash in Hand:</td>
                                        <td><span v-if="totData[0]['totalbal'] >= 0">{{totData[0]['totalbal']}}</span></td>
                                        <td><span v-if="totData[0]['totalbal'] < 0">{{totData[0]['totalbal']}}</span></td>
                                    </tr>
                                  
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>

    </div>
</template>

<script>
export default {
    name: "DayBook",
    data(){
        return{
            activedate:'',
            token: '',
            dayData: [],
            totData: [],
        }
    },
    mounted(){
        this.activedate = localStorage.getItem('ad');
        this.token = localStorage.getItem(this.$ut)
        this.loadData();
    },
    methods: {
        updateDayBook(id){
        let $description = document.getElementById('d'+id).value;
        let $amtin = document.getElementById('ai'+id).value;
        let $amtout = document.getElementById('ao'+id).value;
        
        const params = JSON.stringify({
          id: id,
          description: $description,
          amtin: $amtin,
          amtout : $amtout,
          activedate : this.activedate,
        })


                 let loader = this.$loading.show({
                    // Optional parameters
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                    onCancel: this.onCancel,
                    color: '#f00',
                    loader: 'spinner', //spinner, bars, dots
                    width: 128,
                    height: 128,
                    opacity: .5,
                });

        this.$df.post(this.$au + "dailydaybook",params,{headers : {"Authorization" : `Bearer ${this.token}`}}
        ).then(res => {
          
          this.dayData = res.data.daydata;
          this.totData = res.data.daytotals;
          
          loader.hide()

        }).catch(err =>{
            loader.hide()
            console.log(err)
        })
        
    },

     exportReport(){
            // var element = document.getElementById('topdf')
            // html2pdf(element);
        },
        xlsReport(){
            // var element = document.getElementById('topdf')
            // tableToExcel('topdf', 'W3C Example Table')

        },

    loadData(){
          const params = JSON.stringify({
            activedate: this.activedate,
          })
          this.$df.post(this.$au + "dailydaybook",params,{headers : {"Authorization" : `Bearer ${this.token}`}}
          ).then(res =>{
              
              this.dayData = res.data.daydata
              this.totData = res.data.daytotals;
              
            })
    }
  },
}
</script>

<style scoped>
@media only screen and (max-width: 374px)  {
 

   .table{
    font-size:10px;
    line-height:10px;
 
  }
  .table tr td{
    padding: 1px;
    margin:1px;
  }

  
  small {
  font-size: 10px;
}
}

@media (min-width: 375px) and (max-width: 899px) {
   .table{
    font-size:10px;
    line-height:20px;
 
  }

  .table tr td{
    padding: 2px;
    margin:5px;
  }
  small {
  font-size: 10px;
}
}

@media (min-width: 900px) and (max-width: 1029px) {
   .table{
    font-size:14px;
    line-height:20px;
 
  }

  .table tr td{
    padding: 2px;
    margin:5px;
  }
  small {
  font-size: 10px;
}
}

@media only screen and (min-width: 1030px) and (max-width: 1439px) {


}

@media only screen and (min-width: 1440px) and (max-width: 3840px) {

}
</style>