<template>
<div>
    <div class="mb-0">
        <!-- Fixed navbar -->
            <nav class="navbar navbar-expand navbar-light py-2 bg-default m-0">
                <div class="col-auto px-2">
                   <h5 class="mb-0">{{profileData.companyname}}</h5>
                   <small>{{profileData.companyaddress}}</small>
                   <p><small>({{profileData.companygstin}})</small></p>
                    
               </div>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNav">
                    <div class="navbar-nav mr-auto">
                        &nbsp;
                    </div>
                    <ul class="navbar-nav">

                        <!-- <li class="nav-item mr-2">
                            <opening-bal-modal></opening-bal-modal>
                        </li> -->

                        <li class="nav-item">
                            <a href="/settings" class="text-light">
                                <span class="material-icons" style="font-size: 32px;">settings</span>
                            </a>
                        </li>

                        <li class="nav-item ml-2">
                            <date-modal></date-modal>
                        </li>
                        <li class="nav-item ml-2">
                            <a href="/login" class="text-light">
                                <span class="material-icons" style="font-size: 32px;">logout</span>
                            </a>
                        </li>
                    </ul>
                    
                </div>
            </nav>
     
        <!-- <header class="header">
            <div class="row">
                 <div class="col-auto px-0">
                    <button class="menu-btn btn btn-40 btn-link back-btn" type="button">
                        <span class="material-icons">keyboard_arrow_left</span>
                    </button>
                </div> 
               <div class="col-auto px-4 text-light">
                   <h5 class="mb-0">{{profileData.companyname}}</h5>
                   <small>{{profileData.companyaddress}}</small>
                   <p><small>({{profileData.companygstin}})</small></p>
                   
               </div>
                
                <div class="col">
                    hello
                </div>
 
                <div class="ml-auto col-auto">
                    <a href="/login" class="text-light">
                        <span class="material-icons">logout</span>
                    </a>
                </div>
            </div>
        </header>
         -->
        
    </div>

            <div class="row justify-content-center  text-center" style="width:100%">
                <div class="col-lg-1 col-2 mb-0">
                    <a href="/salewc" style="text-decoration:none">
                    <div class="icon icon-40 rounded-circle mb-1 bg-default-light text-default"><span class="material-icons">qr_code_2</span></div>
                    <p class="text-white"><small>Sale</small></p>
                    </a>
                </div>
                <div class="col-lg-1 col-2 mb-0">
                    <a href="/purchasewc" style="text-decoration:none">
                    <div class="icon icon-40 rounded-circle mb-1 bg-default-light text-default"><span class="material-icons">swap_horiz</span></div>
                    <p class="text-white"><small>Purchase</small></p>
                    </a>
                    
                </div>
                <div class="col-lg-1 col-2 mb-0">
                     <a href="/demand" style="text-decoration:none">
                    <div class="icon icon-40 rounded-circle mb-1 bg-default-light text-default"><span class="material-icons">sim_card</span></div>
                    <p class="text-white"><small>Demand</small></p>
                     </a>
                </div>
                <div class="col-lg-1 col-2 mb-0">
                     <a href="/daybook" style="text-decoration:none">
                    <div class="icon icon-40 rounded-circle mb-1 bg-default-light text-default"><span class="material-icons">account_circle</span></div>
                    <p class="text-white"><small>Daybook</small></p>
                     </a>
                </div>
                <div class="col-lg-1 col-2 mb-0">
                     <pos-modal></pos-modal>
                </div>                
            </div>



</div>
  
</template>

<script>
import DateModal from './DateModal.vue'
import PosModal from './PosModal.vue'
// import OpeningBalModal from './OpeningBalModal.vue'


export default {
    components: { DateModal,PosModal
    },
    name: 'AppHead',
    data(){
        return{
            company_name : 'Daily Inventory',
            profileData: [],
          
        }
    },
    mounted(){
        this.profileData = JSON.parse(this.dec(localStorage.getItem(this.$ud)));
        
    },
}
</script>

<style scoped>
.swal2-overflow {
  overflow-x: visible;
  overflow-y: visible;
}
</style>