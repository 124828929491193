<template>
    <div>
            <main class="flex-shrink-0 main has-footer">
             
            <div class="container h-100">
                <div class="card mb-4">
                    <div class="card-header">
                        <h6 class="subtitle mb-0">
                            <div class="avatar avatar-40 bg-default-light text-default rounded mr-2"><span class="material-icons vm">lock</span></div>
                            Change Password
                        </h6>
                    </div>
                    <div class="card-body">
                        <div class="form-group float-label active">
                            <label>Current Password</label>
                            <input type="password" v-model="currentpass" class="form-control">
                            
                        </div>
                        <div class="form-group float-label">
                            <label>New Password</label>
                            <input type="password" v-model="newpass" class="form-control">
                            
                        </div>
                        <div class="form-group float-label">
                            <label>Confirm New Password</label>
                            <input type="password" v-model="confirmnewpass" class="form-control" >
                            
                        </div>
                    </div>
                    <div class="card-footer">
                        <button class="btn btn-block btn-default rounded" @click="changePass()">Update Password</button>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
export default {
    name: 'ChangePassword',
    data(){
        return{
            currentpass : '',
            newpass: '',
            confirmnewpass: '',
            token: '',
        }
    },
    mounted(){
        this.token = localStorage.getItem(this.$ut);
    },
    methods:{
        changePass(){
            
            if(this.newpass == this.confirmnewpass){
                const params = JSON.stringify({
                    currentpass : this.currentpass,
                    newpass : this.newpass,
                })
                this.$confirm('Update your Password?','Confirm:').then(() =>{
                let loader = this.$loading.show({
                    // Optional parameters
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                    onCancel: this.onCancel,
                    color: '#f00',
                    loader: 'spinner', //spinner, bars, dots
                    width: 128,
                    height: 128,
                    opacity: .5,
                });                 
                 
                 
                 
                 this.$df.post(this.$au + "changepass",params,{headers : {"Authorization" : `Bearer ${this.token}`}}
                    ).then(res => {
            
                    this.$alert(res.data.message)
                    if(res.data.status){
                        window.location = "/login"
                    }
                    loader.hide()
                    }).catch(err =>{
                            loader.hide()
                            console.log(err)
                    })
                })   
            } else {
                this.$alert('pass does not match')
            }
        }
    }
}
</script>
