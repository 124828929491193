<template>
  <div>
    <!-- Begin page content -->
    <main class="flex-shrink-0 main">
      <!-- page content start -->
      <div class="main-container mb-5">
        <div class="container">
          <div class="row mb-1">
            <div class="col-lg-4 col-xs-12 mt-2">
              <h6>Sale Working date: {{activedate}}</h6>  
            </div>
            <div class="search-wrapper panel-heading col-lg-8 col-xs-12 text-right">
                <input class="form-control" type="text" v-model="searchQuery" placeholder="Search party" />
            </div> 
          </div>
          
          <div class="accordion" id="accordionExample">
            <!-- opening stock -->
            <div class="card mb-1">
              <div class="card-header collapsed" :id="`headingopening`" :style="`background-color:yellow`"
                      data-toggle="collapse"
                      :data-target="`#collapseopening`"
                      aria-expanded="false"
                      :aria-controls="`collapseopening`">
                    <div class="row">
                      <div class="col-auto p-0 ml-2">
                          <h6 :style="`color:#000`">Opening Stock</h6>
                      </div>
                      <div class="col">&nbsp;</div>
                      <div class="col-auto" @click="togglestock(closingData[0]['itemname'],closingData[0]['brand'])">
                      <h6 style="background-color:red;color:white;padding:5px;cursor:pointer;border-radius:20%">Stock Information</h6></div>
                    </div>
              </div>

              <div
                id="collapseopening"
                class="collapse"
                aria-labelledby="headingopening"
                data-parent="#accordionExample"
              >
                <div class="card-body">
                  <div class="d-flex flex-wrap">

                    <div class="p-2 bd-highlight border"  v-for="(opdata) in openingData" :key="opdata.id"  style="background-color:#fff;color:#000">
                      <small><b>{{opdata.itemname}}-{{opdata.brand}}</b></small> |
                      <small>{{roundToTwo(opdata.qty)}}</small>
                    </div>
                  </div>



                  <div class="card-footer">
                    <!-- sale information -->
                    <div
                      class="
                        d-flex
                        flex-wrap
                        align-items-center
                        justify-content-center
                      "
                    >
                    </div>
                    <!-- sale information end -->

                  </div>
                </div>
              </div>

            </div>
            <!-- ledger data -->
            <div class="card mb-1" v-for="data in resultQuery" :key="data.acid">
              <div class="card-header" :id="`heading${data.acid}`" :style="`background-color:${data.acbgcolor}`">
                  <div class="row" >
                    <div class="col-auto align-self-center pr-0">
                      <i class="material-icons text-default">account_circle</i>
                    </div>
                    <div class="col"  @click="togglesale(data.acname,data.acid)"
                      data-toggle="collapse"
                      :data-target="`#collapse${data.acid}`"
                      aria-expanded="false"
                      :aria-controls="`collapse${data.acid}`">
                      <h6 :style="`color:${data.acforecolor}`">{{data.acname}}</h6>
                      <p class="small"><span v-if="data.actype>1"><b>Op: </b><span class="material-icons" style="font-size:10px">currency_rupee</span>{{ Number(data.openingbal).toFixed(0) }} |<b> Sale: </b> <span class="material-icons" style="font-size:10px">currency_rupee</span>{{ Number(data.saletotal).toFixed(0) }} |<b> (+/-): </b> <span class="material-icons" style="font-size:10px">currency_rupee</span>{{ Number(data.additions - data.deductions).toFixed(0)}} | <b> Paid: </b> <span class="material-icons" style="font-size:10px">currency_rupee</span>{{ Number(data.amountpaid).toFixed(0) }}</span></p>
                    </div>
                    <div class="col-auto pr-1">
                        <div class="text-primary"><h5 v-if="data.actype>1"><b> Net: </b> <span class="material-icons" style="font-size:10px">currency_rupee</span>{{ Number(data.amountpayable).toFixed(0)}}</h5></div>
                        <a href="javascript:void(0)" data-toggle="modal" data-target="#addpayments" class="btn btn-primary btn-sm">
                          Add Payment
                        </a>
                    </div>

                  </div>
              </div>

              <div
                :id="`collapse${data.acid}`"
                class="collapse"
                :aria-labelledby="`heading${data.acid}`"
                data-parent="#accordionExample"
              >
                <!-- <div class="card-body">
                  <div class="d-flex flex-wrap">

                    <div class="p-2 bd-highlight border"  v-for="(items) in itemData" :key="items.id"  :style="`background-color:${items.bgcolor};color:${items.fontcolor}`">
                      <small>{{items.itemname}}-{{items.brand}}</small> |
                      <small>{{items.qty}}x{{items.rate}}=<span class="material-icons" style="font-size:10px">currency_rupee</span>{{items.subtotal}}</small>
                    </div>

                  </div>

                  
                  <div v-if="data.actype > 1">
                      <small><b>Opening: </b><span class="material-icons" style="font-size:10px">currency_rupee</span>{{ paymentData.reduce((a, c) => a + parseInt(c.openingbal), 0) }} |<b> Sale: </b> <span class="material-icons" style="font-size:10px">currency_rupee</span>{{ paymentData.reduce((a, c) => a + parseInt(c.saletotal), 0) }} |<b> Additions: </b> <span class="material-icons" style="font-size:10px">currency_rupee</span>{{ paymentData.reduce((a, c) => a + parseInt(c.additions), 0) }} | <b> Deductions: </b> <span class="material-icons" style="font-size:10px">currency_rupee</span>{{ paymentData.reduce((a, c) => a + parseInt(c.deductions), 0) }} | <b> Paid: </b> <span class="material-icons" style="font-size:10px">currency_rupee</span>{{ paymentData.reduce((a, c) => a + parseInt(c.amountpaid), 0) }} | <b> Net Payable: </b> <span class="material-icons" style="font-size:10px">currency_rupee</span>{{ paymentData.reduce((a, c) => a + parseInt(c.amountpayable), 0) }}</small>
                  </div>

                </div> -->
              </div>

            </div>

            <!-- closing stock -->
            <div class="card mb-1">
              <div class="card-header" :id="`headingclosing`" :style="`background-color:yellow`">
                  <div class="row" >
                    <div class="col-auto align-self-center pr-0">
                      <i class="material-icons text-default">stock</i>
                    </div>
                    <div class="col" @click="togglestock(closingData[0]['itemname'],closingData[0]['brand'])">
                      <h6>Closing Stock</h6>
                    </div>
                    <div class="col-auto pr-1">
                        &nbsp;
                    </div>
                  </div>
              </div>

              <div id="collapseclosing" class="collapse" aria-labelledby="headingclosing" data-parent="#accordionExample">
                <div class="card-body">
                  <div class="d-flex flex-wrap">
                    <!-- <div class="p-2 bd-highlight border"  v-for="(cldata) in resultStockQuery" :key="cldata.id"  style="background-color:#fff;color:#000">
                      <button class="btn btn-primary" @click="togglestock(cldata.itemname,cldata.brand)">{{cldata.itemname}}-{{cldata.brand}} ({{cldata.qty}})</button>
                    </div> -->
                  </div>

                  <div class="card-footer">
                    <!-- sale information -->
                    <div class="d-flex flex-wrap align-items-center justify-content-center">
                    </div>
                    <!-- sale information end -->

                  </div>
                </div>
              </div>

            </div>
            <!-- closing ends -->

                   <!-- toggle form sale -->
                    <div class="slidein" :class="opensale ? 'open' : ''">
                      <div class="row">
                        <div class="col-8">
                          <h3><a class="text-dark" href="javascript:void(0)" @click="togglesale">
                            <span class="material-icons">cancel</span>
                            </a> {{selectedAccount}} ({{activedate}})
                          </h3>
                        </div>
                        <div class="col-4 text-right" >
                          <a href="javascript:void(0)"  class="btn btn-primary" data-toggle="modal" data-target="#addpayments">
                              Add Payments
                            </a>
                        </div>
                      </div>

                      <div class="container-fluid">
                          <div class="row" style="overflow-y: scroll; max-height: 728px">
                            <!-- Sale Entry -->
                            <div class="col-xlg-7 col-lg-7 col-sm-12 col-xs-12">
                              <div class="row">
                                <div class="col-12">
                                  <div class="row">
                                      <div class="col-4">
                                        <h6 class="p-2">Sales </h6>
                                        
                                      </div>
                                      <div class="col-4">
                                           <span style="font-size:25px;color:red">Count:{{counter}}</span> 
                                      </div>
                                      <div class="col-4 text-right mb-1">
                                          <button v-on:click="fetchData(selectedAccount,selectedAccountId)" class="btn btn-info" style="width:120px;font-size:15px;">Save</button>
                                      </div>
                                    </div>
                                  <div style="overflow-y: scroll; max-height: 368px;border-bottom:1px solid #000000" >
                                    
                                      <table class="table  table-bordered">
                                        <thead><th>Item</th><th>Brand</th><th>Qty</th><th>Rate</th><th>Ret</th><th>Dmg</th><th>Total</th></thead>
                                        <tbody>
                                          <tr v-for="(brand) in itemData" :key="brand.id">
                                            <td >{{brand.itemname}}</td>
                                            <td >{{brand.brand}}</td>
                                            <td>
                                              <input
                                                type="number"
                                                step="any"
                                                inputmode="numeric"
                                                :id="`q${brand.id}`"
                                                :value="brand.qty"
                                                v-on:blur="updateSale(brand.id)"
                                                @focus="$event.target.select()"
                                                :style="(brand.qty>0)?'background-color:#8DE24A':''"
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type="number"
                                                step="any"
                                                inputmode="numeric"
                                                :id="`r${brand.id}`"
                                                :value="brand.rate"
                                                v-on:blur="updateSale(brand.id)"
                                                @focus="$event.target.select()"
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type="number"
                                                step="any"
                                                inputmode="numeric"
                                                :id="`re${brand.id}`"
                                                :value="brand.returnqty"
                                                v-on:blur="updateSale(brand.id)"
                                                @focus="$event.target.select()"
                                                :style="(brand.returnqty>0)?'background-color:#FFC300':''"
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type="number"
                                                step="any"
                                                inputmode="numeric"
                                                :id="`d${brand.id}`"
                                                :value="brand.damageqty"
                                                v-on:blur="updateSale(brand.id)"
                                                @focus="$event.target.select()"
                                                :style="(brand.damageqty>0)?'background-color:#FF5733':''"
                                              />
                                            </td>



                                            <td>
                                              <input
                                                readonly="readonly"
                                                :id="`stot${brand.id}`"
                                                :value="Number(brand.subtotal).toFixed(0)"
                                                type="number"
                                                :style="(brand.subtotal>0)?'background-color:#8DE24A':''"
                                              />
                                            </td>

                                          </tr>
                                        </tbody>
                                      </table>
                                  </div>
                                </div>

                                <div class="col-12">
                                  <div style="overflow: auto; max-height: 330px;margin-top:20px" >
                                          <h6>Additional Details</h6>
                                          <table class="table  table-bordered">
                                          <tr>
                                            <th style="width:30%">Desc</th>
                                            <th style="width:18%">Add</th>
                                            <th style="width:18%">Ded</th>
                                            <th>CF</th>
                                            <th>Daily</th>
                                            
                                          </tr>
                                          <tr v-for="(detail) in detailData" :key="detail.id">
                                            <td>
                                              <input
                                                class="input-custom"
                                                step="any"
                                                type="text"
                                                :id="`dd${detail.id}`"
                                                @keyup = "changeEntryMode()"
                                                v-on:blur="updateDetail(detail.id)"
                                                :value="detail.description"     
                                                @focus="$event.target.select()"
                                                placeholder="Description"
                                                
                                              />
                                              </td>
                                            <td>
                                              <input
                                                class="input-custom"
                                                type="number"
                                                step="any"
                                                inputmode="numeric"
                                                :id="`ai${detail.id}`"
                                                @keyup = "changeEntryMode()"
                                                v-on:blur="updateDetail(detail.id)"
                                                :value="detail.amtin"       
                                                @focus="$event.target.select()"
                                                min="0"
                                                placeholder="0"
                                              />
                                            </td>
                                            <td>
                                              
                                              <input
                                                class="input-custom"
                                                type="number"
                                                step="any"
                                                inputmode="numeric"
                                                :id="`ao${detail.id}`"
                                                @keyup = "changeEntryMode()"
                                                v-on:blur="updateDetail(detail.id)"
                                                :value="detail.amtout"   
                                                @focus="$event.target.select()"
                                                min="0"
                                                placeholder="0"
                                              />
                                              
                                            </td>
                                            <td>
                                              <div class="custom-control custom-switch" style="width:100%">
                                                <input type="checkbox" style="width:100%;z-index:99" class="custom-control-input switch-danger" :id="`iscf${detail.id}`" @click="updateDetail(detail.id)" :checked="`${(detail.iscf==1)?'checked':''}`">
                                                <label class="custom-control-label" :for="`iscf${detail.id}`"></label>
                                              </div>
                                              
                                            </td>
                                            <td>

                                              <div class="custom-control custom-switch" style="width:100%">
                                                <input type="checkbox" style="width:100%;z-index:99" class="custom-control-input switch-danger" :id="`isdaily${detail.id}`" @click="updateDetail(detail.id)" :checked="`${(detail.isdaily==1)?'checked':''}`">
                                                <label class="custom-control-label" :for="`isdaily${detail.id}`"></label>
                                              </div>


                                            </td>
                                            
                                          </tr>
                                          <tr>
                                            <td>Total:</td>
                                            <td>{{totaladditions}}</td>
                                            <td>{{totaldeductions}}</td>
                                            <td colspan="2">&nbsp;</td>
                                          </tr>
                                        </table>
                                  </div>
                                </div>

                              
                              </div>

                            </div> 
                            <!-- right section -->
                            <div class="col-xlg-5 col-lg-5 col-sm-12 col-xs-12">
                              
                              <div class="row">
                                  <!-- payments -->
                                  <div class="col-xlg-12 col-lg-12 col-sm-12 col-xs-12">
                                      <div style="overflow: auto;max-height:400px">
                                        <div v-for="payments in paymentData" :key="payments.id">
                                          <h6>Payment Summary</h6>
                                          <table class="table  table-bordered">  
                                            <tr>
                                              <td>Opening</td>
                                              <td>
                                                <input
                                                  class="input-custom"
                                                  step="any"
                                                  type="number"
                                                  inputmode="numeric"
                                                  :id="`ob${payments.id}`"
                                                  :value="payments.openingbal"
                                                  @keyup = "changeEntryMode()"
                                                  v-on:blur="updatePayments(payments.id)"
                                                  @focus="$event.target.select()"
                                                />
                                                </td>
                                            </tr>
                                            <tr>
                                              <td>Actual Sale</td>
                                              <td>
                                                <input
                                                  class="input-custom bg-danger text-light"
                                                  type="number"
                                                  inputmode="numeric"
                                                  readonly = "readonly"
                                                  :id="`st${payments.id}`"
                                                  :value="Number(payments.saletotal)+Number(payments.retamount)+Number(payments.dmgamount)"
                                                />
                                                </td>
                                            </tr>      
                                            <tr>
                                              <td>Returns</td>
                                              <td>
                                                <input
                                                  class="input-custom bg-info"
                                                  type="number"
                                                  inputmode="numeric"
                                                  readonly = "readonly"
                                                  :id="`od${payments.id}`"
                                                  :value="payments.retamount"
                                                  @focus="$event.target.select()"
                                                />
                                                </td>
                                            </tr> 
                                            <tr>
                                              <td>Damages</td>
                                              <td>
                                                <input
                                                  class="input-custom bg-info"
                                                  type="number"
                                                  inputmode="numeric"
                                                  readonly = "readonly"
                                                  :id="`od${payments.id}`"
                                                  :value="payments.dmgamount"
                                                  @focus="$event.target.select()"
                                                />
                                                </td>
                                            </tr>                                                                                     
                                            <tr>
                                              <td>Recorded Sale</td>
                                              <td>
                                                <input
                                                  class="input-custom bg-danger text-light"
                                                  type="number"
                                                  inputmode="numeric"
                                                  readonly = "readonly"
                                                  :id="`st${payments.id}`"
                                                  :value="payments.saletotal"
                                                />
                                                </td>
                                            </tr>
                                            <tr>
                                              <td>Additions</td>
                                              <td>
                                                <input
                                                  class="input-custom bg-info"
                                                  type="number"
                                                  inputmode="numeric"
                                                  readonly = "readonly"
                                                  :id="`oa${payments.id}`"
                                                  :value="payments.additions"
                                                />
                                                </td>
                                            </tr>
                                            <tr>
                                              <td>Deductions</td>
                                              <td>
                                                <input
                                                  class="input-custom bg-info"
                                                  type="number"
                                                  inputmode="numeric"
                                                  readonly = "readonly"
                                                  :id="`od${payments.id}`"
                                                  :value="payments.deductions"
                                                   />
                                                </td>
                                            </tr> 
                                                                                      
                                            <tr>
                                              <td>Paid</td>
                                              <td>
                                                <input
                                                  class="input-custom"
                                                  type="number"
                                                  step="any"
                                                  inputmode="numeric"
                                                  :id="`p${payments.id}`"
                                                  :value="payments.amountpaid"
                                                  @keyup = "changeEntryMode()"
                                                  v-on:blur="updatePayments(payments.id)"
                                                  @focus="$event.target.select()"
                                                  min="0"
                                                  placeholder="0"
                                                />
                                                </td>
                                            </tr>
                                            <tr>
                                              <td>Net Payable</td>
                                              <td>
                                                <input
                                                  class="input-readonly bg-warning"
                                                  type="number"
                                                  step="any"
                                                  readonly="readonly"
                                                  :id="`np${payments.id}`"
                                                  :value="Number(payments.amountpayable).toFixed(0)"
                                                />
                                                </td>
                                            </tr>
                  
                                          </table>
                                        </div> 
                                      </div>
                                  </div>


                              </div>


                              <div class="row">
                                  <!-- Crates -->
                                  <div class="col-xlg-12 col-lg-12 col-sm-12 col-xs-12">
                                    <div stype="overflow:auto;max-height:400px">
                                      <h6>Crates Detail</h6>
                                      <div v-for="payments in paymentData" :key="payments.id">
                                      <table class="table  table-bordered">  
                                        <tr>
                                          <td>Opening</td>
                                          <td>
                                            <input
                                              class="input-custom"
                                              step="any"
                                              type="number"
                                              inputmode="numeric"
                                              :id="`co${payments.id}`"
                                              :value="payments.crateopening"
                                              v-on:blur="updateCrates(payments.id)"
                                              @focus="$event.target.select()"
                                            />
                                            </td>
                                        </tr>
                                        <tr>
                                          <td>Issue</td>
                                          <td>
                                            <input
                                              class="input-custom"
                                              type="number"
                                              step="any"
                                              inputmode="numeric"
                                              v-on:blur="updateCrates(payments.id)"
                                              :id="`ci${payments.id}`"
                                              :value="payments.crateissue"
                                              @focus="$event.target.select()"
                                              />
                                            </td>
                                        </tr>
                                        <tr>
                                          <td>Return</td>
                                          <td>
                                            <input
                                              class="input-custom"
                                              type="number"
                                              step="any"
                                              inputmode="numeric"
                                              v-on:blur="updateCrates(payments.id)"
                                              :id="`cr${payments.id}`"
                                              :value="payments.cratereturn"
                                              @focus="$event.target.select()"
                                            />
                                            </td>
                                        </tr>
                                        <tr>
                                          <td>Balance</td>
                                          <td>
                                            <input
                                              class="input-readonly bg-warning"
                                              type="number"
                                              step="any"
                                              readonly="readonly"
                                              :id="`cb${payments.id}`"
                                              :value="payments.cratebal"
                                              placeholder="0"
                                            />
                                            </td>
                                        </tr>
              
                                      </table>
                                      </div>
                                    </div>
                                  </div>
                                  <!-- Payments Detail -->
                                  <div class="col-xlg-12 col-lg-12 col-sm-12 col-xs-12">
                                    <div stype="overflow:auto; max-height:400px">
                                      <h6>Payments Detail</h6>
                                      <table class="table table-bordered">
                                      <tr>
                                        <th style="width:20%">Type</th>
                                        <th style="width:20%">Amount</th>
                                        <th style="width:40%">Description</th>
                                        <th style="width:20%">Verified</th>
                                        
                                        
                                      </tr>
                                      <tr v-for="(pddata) in paymentsData" :key="pddata.id">
                                        <td>
                                          <input
                                            class="input-custom"
                                            step="any"
                                            type="text"
                                            :id="`pmd${pddata.id}`"
                                            :value="pddata.paymentmode"     
                                            @focus="$event.target.select()"
                                            
                                            
                                          />
                                          </td>
                                        <td>
                                          <input
                                            class="input-custom"
                                            type="number"
                                            step="any"
                                            inputmode="numeric"
                                            :id="`ai${pddata.id}`" 
                                            :value="pddata.amount"       
                                            @focus="$event.target.select()"
                                            min="0"
                                          />
                                        </td>
                                        <td>
                                          
                                          <input
                                            class="input-custom"
                                            type="text"
                                            :id="`am${pddata.id}`"
                                            :value="pddata.remarks"   
                                            @focus="$event.target.select()"
                                            
                                          />
                                          
                                        </td>
                                        <td>
                                          <div class="custom-control custom-switch" style="width:100%">
                                            <input type="checkbox" style="width:100%;z-index:99" class="custom-control-input switch-danger" :id="`isap${pddata.id}`" @click="paymentApproval(pddata.id)" :checked="`${(pddata.isapproved==1)?'checked':''}`">
                                            <label class="custom-control-label" :for="`isap${pddata.id}`"></label>
                                          </div>
                                          
                                        </td>
                                          
                                      </tr>
                                    </table>
                                    </div>
                                  </div> 
                              </div>


                            </div>
                          </div>
                      </div>
                      <!-- <button class="close-btn" @click="togglesale">X</button> -->
                    </div>
                    <!-- toggle form sale end -->


                   <!-- toggle form stock -->
                    <div class="slidein" :class="openstock ? 'open' : ''">
                      <div class="row">
                        <div class="col-8">
                          <h3><a class="text-dark" href="javascript:void(0)" @click="togglestock">
                            <span class="material-icons">cancel</span>
                             {{activedate}}
                            </a>
                          </h3>
                        </div>
                        <div class="col-4 text-right" >
                          <!-- <a href="javascript:void(0)"  class="btn btn-primary" data-toggle="modal" data-target="#addpayments">
                              Add Payments
                            </a> -->
                        </div>
                      </div>

                      <div class="container-fluid">
                          <div class="row" style="overflow-y: scroll; max-height: 584px">
                            <!-- Sale Entry -->
                            <div class="col-lg-3 col-md-4 col-xs-6">
                               <input class="form-control" type="text" v-model="searchStockQuery" placeholder="Search item" />
                              <div style="overflow: auto; max-height: 584px;margin-top:20px" >
                                  <table class="table  table-bordered">
                                    <tr>
                                      <th style="width:100%">Items List</th>
                                    </tr>
                                    <tr v-for="(cldata) in resultStockQuery" :key="cldata.id" :style="cldata.qty!=0?'background-color:#D3F98D':''" class="text-left">
                                      <td><a class="btn btn-link text-left m-0 p-0" @click="fetchStockData(cldata.itemname,cldata.brand)" style="border:none:width:100%">{{cldata.itemname}} {{cldata.brand}} ({{getClosingData(cldata.itemname,cldata.brand)}}) </a></td>
                                    </tr>
                                  </table>
                              </div>
                            </div>
                            <div class="col-lg-9 col-md-8 col-xs-6">
                              <div class="row">
                                <div class="col-12">
                                  <h3>{{selectedItem}} ({{selectedBrand}})</h3>
                                </div>
                              </div>
                              <div class="row">
                                 
                                <div class="col-xlg-12 col-lg-12 col-sm-12 col-xs-12">
                                  <div class="row">
                                    <div class="col-6">
                                      
                                      <h2>Opening Bal:  {{roundToTwo(stockopeningbal)}}</h2> 
                                      <span>Adjustment: <input type="number"><button class="btn btn-primary">Adjust</button></span> 
                                      
                                    </div>
                                    <div class="col-6">
                                        <h2>Closing Bal: {{roundToTwo(stockclosingbal)}}</h2> 
                                    </div>
                                  </div>
                                </div>
                                <div class="col-xlg-6 col-lg-6 col-sm-12 col-xs-12">
                                      <div style="overflow: auto; max-height: 584px;margin-top:20px" >
                                              <h6>Purchases</h6>
                                              <table class="table  table-bordered">
                                                <tr>
                                                  <th style="width:40%">Supplier</th>
                                                  <th style="width:20%">Buy</th>
                                                  <th style="width:20%">Return</th>
                                                  <th style="width:20%">Damage</th>
                                                </tr>
                                                <tr v-for="(stock) in purchaseData" :key="stock.id" :style="stock.qty>0?'background-color:#D3F98D':''">
                                                  <td>{{stock.acname}}</td>
                                                  <td>{{stock.qty}}</td>
                                                  <td>{{stock.returnqty}}</td>
                                                  <td>{{stock.damageqty}}</td>
                                                </tr>
                                            </table>
                                      </div>
                                </div> 
                                <!-- right section -->
                                <div class="col-xlg-6 col-lg-6 col-sm-12 col-xs-12">
                                      <div style="overflow: auto; max-height: 484px;margin-top:20px" >
                                              <h6>Sales</h6>
                                              <table class="table  table-bordered">
                                                <tr>
                                                  <th style="width:40%">Buyer</th>
                                                  <th style="width:20%">Issue</th>
                                                  <th style="width:20%">Return</th>
                                                  <th style="width:20%">Damage</th>
                                                </tr>
                                                <tr v-for="(stock) in saleData" :key="stock.id"  :style="stock.qty>0?'background-color:#D3F98D':''">
                                                  <td>{{stock.acname}}</td>
                                                  <td>{{stock.qty}}</td>
                                                  <td>{{stock.returnqty}}</td>
                                                  <td>{{stock.damageqty}}</td>
                                                </tr>
                                            </table>
                                      </div>

                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                      <!-- <button class="close-btn" @click="togglesale">X</button> -->
                    </div>
                    <!-- toggle form stock end -->


          </div>
        </div>
      </div>
        <!-- Payments Modal -->
        <div  class="modal fade" id="addpayments" tabindex="-1" role="dialog" aria-labelledby="dateModalLabel" aria-hidden="true" >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 id="exampleModalLabel" class="modal-title">Add Payment</h5>
                <button type="button" data-dismiss="modal" aria-label="Close" class="close">
                    <span aria-hidden="true">&times;</span>
                </button>
              </div>
               <div class="modal-body">
                  <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                      <div class="form-floating">
                        <select id="paymentType" name="paymentType" required="" class="form-select form-control" v-model="paymentmode">
                          <option value="CASH">CASH</option>
                          <option value="CHEQUE">CHEQUE</option>
                          <option value="NET BANKING">NET BANKING</option>
                          <option value="UPI">UPI</option>
                          <option value="EMI">EMI</option>
                          <option value="OTHER">OTHER</option>
                          <!---->
                        </select>
                        <label for="paymentType">Payment Type</label>
                      </div>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                      <div class="form-floating">
                        <input
                          type="number"
                          name="amount"
                          min="0"
                          step="1.0"
                          class="form-control"
                          ref="amount"
                          v-model="amount"
                          :readonly="showDenom"
                          @focus="$event.target.select()"
                        />
                        <label for="floatingInput">Amount</label>
                      </div>
                    </div>

                    <div
                      class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3"
                    >
                      <div class="form-floating">
                        <input
                          type="text"
                          id="remarks"
                          name="remarks"
                          placeholder="Description"
                          class="form-control"
                          v-model="remarks"
                        />
                        <label for="floatingInput">Description</label>
                      </div>
                    </div>
                    <div
                      class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mt-3"
                      v-if="paymentmode == 'CASH'"
                    >
                      <div class="form-check">
                        <input
                          type="checkbox"
                          value=""
                          class="form-check-input"
                          v-model="showDenom"
                        />
                        <label for="flexCheckDefault" class="form-check-label">
                          Show Cash Denomination
                        </label>
                      </div>
                    </div>
                    <!---->
                  </div>
                  <div class="row mt-3" v-if="showDenom">
                    <div
                      class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-3"
                    >
                      <div class="form-floating">
                        <input
                          type="number"
                          id="note2000"
                          placeholder=""
                          class="form-control"
                          v-model="note2000"
                          v-on:keyup="denomtotal"
                          @focus="$event.target.select()"
                        />
                        <label for="floatingInput">₹ 2000 Note(s)</label>
                      </div>
                    </div>
                    <div
                      class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-3"
                    >
                      <div class="form-floating">
                        <input
                          type="number"
                          id="note500"
                          placeholder=""
                          class="form-control"
                          v-model="note500"
                          v-on:keyup="denomtotal"
                          @focus="$event.target.select()"
                        />
                        <label for="floatingInput">₹ 500 Note(s)</label>
                      </div>
                    </div>
                    <div
                      class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-3"
                    >
                      <div class="form-floating">
                        <input
                          type="number"
                          id="note200"
                          placeholder=""
                          class="form-control"
                          v-model="note200"
                          v-on:keyup="denomtotal"
                          @focus="$event.target.select()"
                        />
                        <label for="floatingInput">₹ 200 Note(s)</label>
                      </div>
                    </div>
                    <div
                      class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-3"
                    >
                      <div class="form-floating">
                        <input
                          type="number"
                          id="note100"
                          placeholder=""
                          class="form-control"
                          v-model="note100"
                          v-on:keyup="denomtotal"
                          @focus="$event.target.select()"
                        />
                        <label for="floatingInput">₹ 100 Note(s)</label>
                      </div>
                    </div>
                    <div
                      class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-3"
                    >
                      <div class="form-floating">
                        <input
                          type="number"
                          id="note50"
                          placeholder=""
                          class="form-control"
                          v-model="note50"
                          v-on:keyup="denomtotal"
                          @focus="$event.target.select()"
                        /><label for="floatingInput">₹ 50 Note(s)</label>
                      </div>
                    </div>
                    <div
                      class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-3"
                    >
                      <div class="form-floating">
                        <input
                          type="number"
                          id="note20"
                          placeholder=""
                          class="form-control"
                          v-model="note20"
                          v-on:keyup="denomtotal"
                          @focus="$event.target.select()"
                        /><label for="floatingInput">₹ 20 Note(s)</label>
                      </div>
                    </div>
                    <div
                      class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-3"
                    >
                      <div class="form-floating">
                        <input
                          type="number"
                          id="note10"
                          placeholder=""
                          class="form-control"
                          v-model="note10"
                          v-on:keyup="denomtotal"
                          @focus="$event.target.select()"
                        /><label for="floatingInput">₹ 10 Note(s)</label>
                      </div>
                    </div>
                    <div
                      class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-3"
                    >
                      <div class="form-floating">
                        <input
                          type="number"
                          id="note5"
                          class="form-control"
                          v-model="note5"
                          v-on:keyup="denomtotal"
                          @focus="$event.target.select()"
                        /><label for="note5">₹ 5 Note(s)</label>
                      </div>
                    </div>
                    <div
                      class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-3"
                    >
                      <div class="form-floating">
                        <input
                          type="number"
                          id="note2"
                          class="form-control"
                          v-model="note2"
                          v-on:keyup="denomtotal"
                          @focus="$event.target.select()"
                        /><label for="floatingInput">₹ 2 Coin(s)</label>
                      </div>
                    </div>
                    <div
                      class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-3"
                    >
                      <div class="form-floating">
                        <input
                          type="number"
                          id="note1"
                          class="form-control"
                          v-model="note1"
                          v-on:keyup="denomtotal"
                          @focus="$event.target.select()"
                        /><label for="floatingInput">₹ 1 Coin(s)</label>
                      </div>
                    </div>
                  </div>
                  <!---->
                </div>
                <div class="modal-footer">
                  <button type="button" data-dismiss="modal" class="btn btn-secondary btn-lg">Close</button>
                  <button @click="updatePaymentsData" class="btn btn-success btn-lg">Save</button>
                </div>
            </div>
          </div>
        </div>
        <!-- Payments Modal Ends -->      
    </main>


  </div>
</template>

<script>



export default {
  
  name: "Saleswc_old",
  data() {
    return {
      opensale: false,
      openstock: false,
      token: "",
      activedate: "",
      entrymode: false,

      acData: [],
      itemData: [],
      detailData: [],
      paymentData: [],
      paymentsData: [],
      crateData: [],
      openingData: [],
      closingData: [],

      purchaseData:[],
      saleData:[],
      stockopeningbal:0,
      stockclosingbal:0,

      selectedAccount:"",
      selectedAccountId:0,
      selectedAccountType:"",

      selectedItem: "",
      selectedBrand: "",

      totalsale:0,
      totaladditions:0,
      totaldeductions:0,
      counter:0,
      searchStockQuery:'',
      searchQuery:'',

      paymentmode:'',
      amount:0,
      remarks:'',
      note1:0,
      note2:0,
      note5:0,
      note10:0,
      note20:0,
      note50:0,
      note100:0,
      note200:0,
      note500:0,
      note2000:0,
      showDenom:false,
     

    };
  },
  computed: {
      resultQuery(){
          if(this.searchQuery!=''){
              return this.acData.filter((item)=>{
                  return item.acname.toLowerCase().indexOf(this.searchQuery.toLowerCase()) !== -1;
                  // return item.brand.toLowerCase().includes(this.searchQuery);
                  // console.log(item)
              })
          }else{
              return this.acData;
          }
      },
      resultStockQuery(){
          if(this.searchStockQuery!=''){
              return this.closingData.filter((item)=>{
                  return item.itemname.toLowerCase().indexOf(this.searchStockQuery.toLowerCase()) !== -1;
                  // return item.brand.toLowerCase().includes(this.searchQuery);
                  // console.log(item)
              })
          }else{
              return this.closingData;
          }
      }      
  },  
  mounted(){
          this.token = localStorage.getItem(this.$ut)
          this.activedate = localStorage.getItem('ad');
          this.loadMaster();
      
  },

  methods: {
    denomtotal(){
      this.amount = Number(this.note1)*1 + Number(this.note2)*2 +Number(this.note5)*5 +Number(this.note10)*10 +Number(this.note20)*20 +Number(this.note50)*50 + Number(this.note100)*100 +Number(this.note200)*200 +Number(this.note500)*500 +Number(this.note2000)*2000
    },

    changeEntryMode(){
      this.entrymode = !this.entrymode
    },

    updateSale(id){
        // if(!this.entrymode){
        //     return
        //  }
        
        let $qty = document.getElementById('q'+id).value;
        let $rate = document.getElementById('r'+id).value;
        let $damageqty = document.getElementById('d'+id).value;
        let $returnqty = document.getElementById('re'+id).value;

        this.itemData.find(itm =>itm.id===id).qty = Number($qty)
        this.itemData.find(itm =>itm.id===id).rate = Number($rate)
        this.itemData.find(itm =>itm.id===id).damageqty = Number($damageqty)
        this.itemData.find(itm =>itm.id===id).returnqty = Number($returnqty)
        


        let $subtotal = (parseFloat($qty) - (parseFloat($damageqty) + parseFloat($returnqty))) * parseFloat($rate);
        this.itemData.find(itm =>itm.id===id).subtotal = Number($subtotal)
        this.counter = this.itemData.reduce(function(n, val) {
            return n + (val.qty != 0);
        }, 0);



        const params = JSON.stringify({
          id: id,
          acid : this.selectedAccountId,
          actype: this.selectedAccountType,
          qty: $qty,
          rate: $rate,
          returnqty : $returnqty,
          damageqty: $damageqty,
          subtotal: $subtotal,
          activedate : this.activedate,
        })
   

        this.$df.post(this.$au + "dailytrans",params,{headers : {"Authorization" : `Bearer ${this.token}`}}
        ).then((res) => {
          
          // this.itemData = res.data.data;
          // this.totalsale = res.data.totalsale
          this.paymentData = res.data.paymentdata
          // this.closingData = res.data.closingdata
          // console.log(res)

        }).catch(err =>{
            console.log(err)
        })
        this.entrymode = !this.entrymode
        




    },


    
    updateDetail(id){
        
        let $description = document.getElementById('dd'+id).value;
        let $amtin = document.getElementById('ai'+id).value;
        let $amtout = document.getElementById('ao'+id).value;
        let $iscf = (document.getElementById('iscf'+id).checked)?1:0;
        let $isdaily = (document.getElementById('isdaily'+id).checked)?1:0;
         
 
        const params = JSON.stringify({
          id: id,
          acid : this.selectedAccountId,
          description: $description,
          amtin: $amtin,
          amtout : $amtout,
          iscf: $iscf,
          isdaily: $isdaily,
          activedate : this.activedate,
        })

        this.$df.post(this.$au + "dailydetails",params,{headers : {"Authorization" : `Bearer ${this.token}`}}
        ).then(res => {
          
          this.detailData = res.data.detaildata;
          this.totaladditions = res.data.totaladditions
          this.totaldeductions = res.data.totaldeductions
          this.paymentData = res.data.paymentdata
         

        })
        this.entrymode = !this.entrymode
  
      

        
    },
    updateCrates(id){

        
        let $crateopening  = document.getElementById('co'+id).value;
        let $crateissue = document.getElementById('ci'+id).value;
        let $cratereturn = document.getElementById('cr'+id).value;
        let $cratebal = parseFloat($crateopening) + parseFloat($crateissue) - parseFloat($cratereturn);
 
        const params = JSON.stringify({
          id: id,
          acid : this.selectedAccountId, 
          crateopening: $crateopening,
          crateissue: $crateissue,
          cratereturn: $cratereturn,
          cratebal: $cratebal,
          activedate : this.activedate,
        })

        this.$df.post(this.$au + "dailycrates",params,{headers : {"Authorization" : `Bearer ${this.token}`}}
        ).then(res => {
          console.log(res)
          this.paymentData = res.data.paymentdata
          

        }).catch(err =>{
            console.log(err)
        })
        this.entrymode = !this.entrymode
   
      
   
        
    },


    updatePayments(id){
     if(!this.entrymode){
        
          return

         }
        
        let $openingbal = document.getElementById('ob'+id).value;
        let $saletotal = document.getElementById('st'+id).value;
        let $otheradditions = document.getElementById('oa'+id).value;
        let $otherdeductions = document.getElementById('od'+id).value;
        let $amountpaid = document.getElementById('p'+id).value;

        let $amountpayable = (parseFloat($openingbal) + parseFloat($saletotal) + (parseFloat($otheradditions) - parseFloat($otherdeductions))) - parseFloat($amountpaid);        const params = JSON.stringify({

          id: id,
          acname: this.selectedAccount,
          acid: this.selectedAccountId,
          actype: this.selectedAccountType,
          activedate: this.activedate,
          openingbal: $openingbal,
          amountpaid: $amountpaid,
          amountpayable : $amountpayable,

        })

        this.$df.post(this.$au + "dailypayments",params,{headers : {"Authorization" : `Bearer ${this.token}`}}
        ).then(res => {
          
          this.paymentData = res.data.paymentdata;

        }).catch(err =>{
            console.log(err)
        })
        this.entrymode = !this.entrymode
      

        
    },




       updatePaymentsData(){


        const params = JSON.stringify({

          acname: this.selectedAccount,
          acid: this.selectedAccountId,
          activedate: this.activedate,
          paymentmode: this.paymentmode,
          amount: this.amount,
          remarks: this.remarks,
          note1: this.note1,
          note2: this.note2,
          note5: this.note5,
          note10: this.note10,
          note20: this.note20,
          note50: this.note50,
          note100: this.note100,
          note200: this.note200,
          note500: this.note500,
          note2000: this.note2000,
          
        })


        let loader = this.$loading.show({
              // Optional parameters
              container: this.fullPage ? null : this.$refs.formContainer,
              canCancel: false,
              onCancel: this.onCancel,
              color: '#f00',
              loader: 'spinner', //spinner, bars, dots
              width: 128,
              height: 128,
              opacity: .5,
          });


        this.$df.post(this.$au + "dailypaymentsdata",params,{headers : {"Authorization" : `Bearer ${this.token}`}}
        ).then(res => {
          this.paymentData = res.data.paymentdata;
          this.paymentsData = res.data.paymentsdata;

           this.amount= 0
           this.remarks= ""
           this.note1= 0
           this.note2= 0
           this.note5= 0
           this.note10= 0
           this.note20= 0
           this.note50= 0
           this.note100= 0
           this.note200= 0
           this.note500= 0
           this.note2000= 0
           this.$refs.amount.focus()

           loader.hide()
        }).catch(err =>{
            loader.hide()
            console.log(err)
        })
        
    },

    paymentApproval(id){
        let $isapproved = (document.getElementById('isap'+id).checked)?1:0;
         
 
        const params = JSON.stringify({
          id: id,
          acid: this.selectedAccountId,
          isapproved: $isapproved,
          activedate : this.activedate,
        })
        console.log(params)
        this.$df.post(this.$au + "paymentsverified",params,{headers : {"Authorization" : `Bearer ${this.token}`}}
        ).then(res => {
          this.paymentsData = res.data.paymentsdata
          this.paymentData = res.data.paymentdata
        })
        
    },

   

    fetchData(acname,acid){
      this.selectedAccount = acname;
      this.selectedAccountId = acid;

      const params = JSON.stringify({
        activedate: this.activedate,
        acid: acid,
      });

      let loader = this.$loading.show({
            // Optional parameters
            container: this.fullPage ? null : this.$refs.formContainer,
            canCancel: false,
            onCancel: this.onCancel,
            color: '#f00',
            loader: 'spinner', //spinner, bars, dots
            width: 128,
            height: 128,
            opacity: .5,
        });

      this.$df.post(this.$au + "dailytransfetch",params,{headers : {"Authorization" : `Bearer ${this.token}`}}
      ).then(res => {
        this.itemData = res.data.data
        this.detailData = res.data.detaildata
        this.paymentData = res.data.paymentdata
        this.paymentsData = res.data.paymentsdata
        this.crateData = res.data.cratedata
        this.totalsale = res.data.totalsale
        this.closingData = res.data.closingdata
        
        this.counter = this.itemData.reduce(function(n, val) {
            return n + (val.qty != 0);
        }, 0);

        loader.hide()
        
      }).catch(err =>{
          loader.hide()
          console.log(err)
      })

      
      
    },  

    fetchStockData(itemname,brand){
      this.selectedItem = itemname;
      this.selectedBrand = brand;

      const params = JSON.stringify({
        activedate: this.activedate,
        itemname: itemname,
        brand:brand,
      });

      let loader = this.$loading.show({
            // Optional parameters
            container: this.fullPage ? null : this.$refs.formContainer,
            canCancel: false,
            onCancel: this.onCancel,
            color: '#f00',
            loader: 'spinner', //spinner, bars, dots
            width: 128,
            height: 128,
            opacity: .5,
        });

      this.$df.post(this.$au + "dailystockfetch",params,{headers : {"Authorization" : `Bearer ${this.token}`}}
      ).then(res => {
        this.purchaseData = res.data.purchasedata
        this.saleData = res.data.saledata
        let opobject = this.openingData.find((item) => item.itemname === itemname && item.brand===brand)
        if(!opobject){
          this.stockopeningbal = 0 
        }else{
          this.stockopeningbal = opobject.qty
        }
        let clobject = this.closingData.find((item) => item.itemname === itemname && item.brand===brand)
        if(!clobject){
          this.stockclosingbal = 0 
        }else{
          this.stockclosingbal = clobject.qty
        }

        loader.hide()
        
      }).catch(err =>{
          loader.hide()
          console.log(err)
      })
      
    },  




// toggle input slide outs ------------------------------
    togglesale(acname,acid) {
      this.opensale = !this.opensale;
      if(!this.opensale){
        this.loadMaster()
      }else{
        this.fetchData(acname,acid)
      }
    },
    togglestock(itemname,brand) {
      this.openstock = !this.openstock;
      this.fetchStockData(itemname,brand)
    },
// toggle end slide outs ----------------------------------



    loadMaster(){
          const params = JSON.stringify({
            activedate: this.activedate,
          })

              let loader = this.$loading.show({
                    // Optional parameters
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                    onCancel: this.onCancel,
                    color: '#f00',
                    loader: 'spinner', //spinner, bars, dots
                    width: 128,
                    height: 128,
                    opacity: .5,
                });

          this.$df.post(this.$au + "dailytransfetch",params,{headers : {"Authorization" : `Bearer ${this.token}`}}
          ).then(res =>{
              // this.acData = res;
              this.openingData = res.data.openingdata
              this.acData = res.data.data
              this.closingData = res.data.closingdata
              loader.hide()
            }).catch(err =>{
                loader.hide()
                console.log(err)
            })
    },
    getClosingData(itemname,brand){
        let result = this.closingData.find((item) => item.itemname === itemname && item.brand===brand).qty
        if(!result){
          result = 0
        }
        return result
        
      },
      roundToTwo(num) {    
        return +(Math.round(num + "e+2")  + "e-2");
      }


  },
};
</script>

<style scoped>
@media only screen and (max-width: 374px)  {
 
  .btn-custom-size{
    width:200px;
    font-size:14px;
    margin-bottom: 0;
    margin-top:0;
  }

   .table{
    font-size:14px;
    line-height:20px;
 
  }

  .table tr td{
    padding: 2px;
    margin:5px;
  }
  .table tr th{
    padding: 2px;
    margin:5px;
  }
  
  small {
  font-size: 10px;
}
}

@media (min-width: 375px) and (max-width: 899px) {
  .btn-custom-size{
    width:200px;
    font-size:14px;
    margin-bottom: 0;
    margin-top:0;
   
  }

   .table{
    font-size:14px;
    line-height:20px;
 
  }

  .table tr td{
    padding: 2px;
    margin:5px;
  }
 .table tr th{
    padding: 2px;
    margin:5px;
  }
  small {
  font-size: 10px;
}
}

@media (min-width: 900px) and (max-width: 1029px) {
  .btn-custom-size{
    width:200px;
    font-size:14px;
    margin-bottom: 0;
    margin-top:0;
   
  }
 
   .table{
    font-size:14px;
    line-height:20px;
 
  }

  .table tr td{
    padding: 2px;
    margin:5px;
  }
   .table tr th{
    padding: 2px;
    margin:5px;
  }
  small {
  font-size: 10px;
}
}

@media only screen and (min-width: 1030px) and (max-width: 1439px) {
  .btn-custom-size{
    width:200px;
    font-size:15px;
   
  }
  .slidein {
    font-size:12px;
  }
     .table{
    font-size:14px;
    line-height:20px;
 
  }

  .table tr td{
    padding: 2px;
    margin:5px;
  }

   .table tr th{
    padding: 2px;
    margin:5px;
  }

  small {
  font-size: 12px;
  }

}

@media only screen and (min-width: 1440px) and (max-width: 3840px) {
  .btn-custom-size{
    width:200px;
    font-size:15px;
   
  }
  .slidein {
    font-size:12px;
  }
  
  .table{
    font-size:14px;
    line-height:20px;
   }

  .table tr td{
    padding: 2px;
    margin:5px;
  }
 .table tr th{
    padding: 2px;
    margin:5px;
  }


  small {
  font-size: 12px;
}

}

.slidein {
  padding: 10px;
  position: fixed;
  z-index: 100;
  top: 0;
  right: -100%;
  color: rgb(0, 0, 0);
  background: rgb(255, 255, 255);
  height: 100%;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
  transition: all 0.5s ease-in-out;
  width: 100vw;
}

/* Set positioning back to 0 when toggled opened */
.open {
  right: 0;
}

/* add a close button in case toggle button is hidden on smaller screens */
.close-btn {
  border: none;
  font-weight: bold;
  font-size: 2em;
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.5em;
}

.toggle {
  margin: 1em;
}

button {
  padding: 0.5em 1em;
  font-size: 1.1em;
}




input[type="number"]{
  width: 100%;
}

input[type="text"]{
  width: 100%;
}

.input-readonly{
  color:black;
  background:rgb(217, 255, 1);
  border:black;
}

/* Removes the clear button from date inputs */
input[type="date"]::-webkit-clear-button {
    display: none;
}

/* Removes the spin button */
input[type="date"]::-webkit-inner-spin-button { 
    display: none;
}

/* Always display the drop down caret */
input[type="date"]::-webkit-calendar-picker-indicator {
    color: #2c3e50;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* A few custom styles for date inputs */
input[type="date"] {
    appearance: none;
    -webkit-appearance: none;
    color: #95a5a6;
    font-family: "Helvetica", arial, sans-serif;
    font-size: 18px;
    border:1px solid #ecf0f1;
    background:#ecf0f1;
    padding:5px;
    display: inline-block !important;
    visibility: visible !important;
}

input[type="date"], focus {
    color: #95a5a6;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
}

table {
  table-layout: fixed ;
  width: 100% ;
}
td {
  width: 25% ;
} 


</style>