<template>
    <div>
        <!-- Button trigger modal -->
        <a href="javascript:void(0)" class="text-light" data-toggle="modal" data-target="#dateModal">  
            <span class="material-icons" style="font-size: 32px;">
                update
            </span>
        </a>

        <!-- Modal -->
        <div class="modal fade" id="dateModal" tabindex="-1" role="dialog" aria-labelledby="dateModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title text-primary" id="dateModalLabel">Change Date</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <input type="date" placeholder="dd-mm-yyyy" class="form-control" v-model="activedate">


            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" @click="setActiveDate()">Change Date</button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                
            </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'DateModal',
  data(){
      return{
          activedate:'',
      }
  },
   mounted(){
      this.activedate = localStorage.getItem('ad');
      
  },
  methods:{
      setActiveDate(){
          localStorage.setItem('ad',this.activedate)
          this.$router.go('/')
      }

  },
 
  
}
</script>