<template>
  <div>
    <!-- Begin page content -->
    <main class="flex-shrink-0 main">
      <!-- {{purchasePymtData}} -->
      <!-- {{paymentData}} -->
      <!-- page content start -->
      <div class="main-container mb-5">
        <div class="container">
          <h6>Working date: {{activedate}}</h6>
          <div class="container mb-2">
                <div class="card">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-6">
                                <h6>Sale Book </h6>
                            </div>
                            <div class="col-6">
                                <span class="float-right text-right" >
                                    <h4>₹ {{totalsale}}</h4>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="form-group float-label active col-11">
                              
                             <select class="form-control" v-model="selectedAccountId" @change="loadEntryTable()">
                                 <option value="0" selected disabled>Select Account</option>
                                 <option v-bind:value="{id:ac.id,text:ac.acname,type:ac.actype}" v-for="ac in acData" :key="ac.id">{{ac.acname}}</option>   
                             </select>
                            </div>
                            <div class="col-1 text-center" style="margin-top:20px;">
                              <a href="/accountssale"> <span class="material-icons" style="font-size:32px">add_circle</span></a>
                            </div>

                        </div>
                        <div class="row" v-if="selectedAccountId.id==0">
                          <div class="col-12">
                            <p>Select the account to view information</p>
                          </div>
                        </div>

                        <div class="row" v-if="itemData.length==0 && selectedAccountId.id>0">
                          <div class="col-12">
                            <p>No items available for this account either attach items or process opening data</p>
                          </div>
                        </div>
                        <!-- sale table starts -->
                        <div class="row"  v-if="selectedAccountId.id!=0 && itemData.length>0">
                          <div class="col-12">
                            <div style="overflow: auto; height: 100%" >
                    
                              <table class="table table-striped table-dark">
                                <tr><td>Item</td><td>Brand</td><td>Stock</td><td>Qty</td><td>Rate</td><td>Total</td></tr>

                                  <tr v-for="(brand) in itemData" :key="brand.id">
                                    
                                    <td >{{brand.itemname}}</td>
                                    <td >{{brand.brand}}</td>
                                    <td >{{getClosingData(brand.itemname,brand.brand)}}</td>
                                    <td>
                                      <input
                                        type="number"
                                        step="any"
                                        inputmode="numeric"
                                        :id="`q${brand.id}`"
                                        :value="brand.qty"
                                         v-on:blur="updateSale(brand.id)"
                                        @focus="$event.target.select()"
                                        min="0"
                                        placeholder="0"
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        step="any"
                                        inputmode="numeric"
                                        :id="`r${brand.id}`"
                                        :value="brand.rate"
                                        v-on:blur="updateSale(brand.id)"
                                        @focus="$event.target.select()"
                                        min="0"
                                        placeholder="0"
                                      />
                                    </td>

                                   

                                    <td>
                                      <input
                                        readonly="readonly"
                                        :value ="brand.subtotal"
                                        step="any"
                                      type="number"
                                      />
                                    </td>

                                  </tr>
                              </table>
                  
                          </div>
                          </div>
                        </div>
                        <!-- sale table ends -->

                        <!-- detail table starts -->
                        <div class="row"  v-if="selectedAccountId.id!=0 && itemData.length>0">
                          <div class="col-lg-6 col-sm-12 col-xs-12">
                            
                            <div style="overflow: auto; height: 100%" >
                              <h6>Additional Details:</h6>
                                 <table class="table table-striped table-dark">
                                    <tr>
                                      <th style="width:30%">Desc</th>
                                      <th style="width:18%;white-space:nowrap">Amt(+)</th>
                                      <th style="width:18%;white-space:nowrap">Amt(-)</th>
                                      <th style="white-space:nowrap">CF</th>
                                      <th style="white-space:nowrap">Daily</th>
                                      
                                    </tr>
                                    <tr v-for="(detail) in detailData" :key="detail.id">
                                      <td>
                                        <input
                                          class="input-custom"
                                          step="any"
                                          type="text"
                                          :id="`dd${detail.id}`"
                                          v-on:blur="updateDetail(detail.id)"
                                          :value="detail.description"     
                                          @focus="$event.target.select()"
                                          placeholder="Description"
                                          
                                        />
                                        </td>
                                      <td>
                                        <input
                                          class="input-custom"
                                          type="number"
                                          step="any"
                                          inputmode="numeric"
                                          :id="`ai${detail.id}`"
                                          v-on:blur="updateDetail(detail.id)"
                                          :value="detail.amtin"       
                                          @focus="$event.target.select()"
                                          min="0"
                                          placeholder="0"
                                        />
                                      </td>
                                      <td>
                                        
                                        <input
                                          class="input-custom"
                                          type="number"
                                          step="any"
                                          inputmode="numeric"
                                          :id="`ao${detail.id}`"
                                          v-on:blur="updateDetail(detail.id)"
                                          :value="detail.amtout"   
                                          @focus="$event.target.select()"
                                          min="0"
                                          placeholder="0"
                                        />
                                        
                                      </td>
                                      <td>
                                        <div class="custom-control custom-switch" style="width:100%">
                                          <input type="checkbox" style="width:100%;z-index:99" class="custom-control-input switch-danger" :id="`iscf${detail.id}`" @click="updateDetail(detail.id)" :checked="`${(detail.iscf==1)?'checked':''}`">
                                          <label class="custom-control-label" :for="`iscf${detail.id}`"></label>
                                        </div>
                                        
                                      </td>
                                      <td>

                                        <div class="custom-control custom-switch" style="width:100%">
                                          <input type="checkbox" style="width:100%;z-index:99" class="custom-control-input switch-danger" :id="`isdaily${detail.id}`" @click="updateDetail(detail.id)" :checked="`${(detail.isdaily==1)?'checked':''}`">
                                          <label class="custom-control-label" :for="`isdaily${detail.id}`"></label>
                                        </div>


                                      </td>
                                      
                                    </tr>
                                    <tr>
                                      <td>Total:</td>
                                      <td>{{totaladditions}}</td>
                                      <td>{{totaldeductions}}</td>
                                      <td colspan="2">&nbsp;</td>
                                    </tr>
                                  </table>
                    
                 
                          </div>
                          </div>

                          <div class="col-lg-6 col-sm-12 col-xs-12">
                            
                            <div style="overflow: auto; height: 100%" >
                              <h6>Payments:</h6>

                                      <table class="table table-striped table-dark" v-for="payments in paymentData" :key="payments.id">  
                                        <tr>
                                          <td>Opening</td>
                                           <td>
                                            <input
                                              class="input-custom"
                                              step="any"
                                              type="number"
                                              inputmode="numeric"
                                              :id="`ob${payments.id}`"
                                              :value="payments.openingbal"
                                              v-on:blur="updatePayments(payments.id)"
                                              @keyup.enter="updatePayments(payments.id)"
                                              @focus="$event.target.select()"
                                              min="0"
                                              placeholder="0"
                                              disabled
                                            />
                                            </td>
                                        </tr>
                                        <tr>
                                          <td>Sales</td>
                                          <td>
                                            <input
                                              class="input-custom bg-danger text-light"
                                              type="number"
                                              step="any"
                                              inputmode="numeric"
                                              readonly = "readonly"
                                              :id="`st${payments.id}`"
                                              :value="payments.saletotal"
                                              @focus="$event.target.select()"
                                              min="0"
                                              placeholder="0"
                                              disabled
                                            />
                                            </td>
                                        </tr>
                                        <tr>
                                          <td>Other Additions</td>
                                          <td>
                                            <input
                                              class="input-custom bg-info"
                                              type="number"
                                              step="any"
                                              inputmode="numeric"
                                              readonly = "readonly"
                                              :id="`oa${payments.id}`"
                                              :value="payments.additions"
                                              @focus="$event.target.select()"
                                              min="0"
                                              placeholder="0"
                                              disabled
                                            />
                                            </td>
                                        </tr>
                                        <tr>
                                          <td>Other Deductions</td>
                                          <td>
                                            <input
                                              class="input-custom bg-info"
                                              type="number"
                                              step="any"
                                              inputmode="numeric"
                                              readonly = "readonly"
                                              :id="`od${payments.id}`"
                                              :value="payments.deductions"
                                              @focus="$event.target.select()"
                                              min="0"
                                              placeholder="0"
                                              disabled
                                            />
                                            </td>
                                        </tr> 
                                        <tr>
                                          <td>Paid</td>
                                          <td>
                                            <input
                                              class="input-custom"
                                              type="number"
                                              step="any"
                                              inputmode="numeric"
                                              :id="`p${payments.id}`"
                                              :value="payments.amountpaid"
                                              v-on:blur="updatePayments(payments.id)"
                                              @keyup.enter="updatePayments(payments.id)"
                                              @focus="$event.target.select()"
                                              min="0"
                                              placeholder="0"
                                              
                                            />
                                            </td>
                                        </tr>
                                        <tr>
                                          <td>Net Payable</td>
                                          <td>
                                            <input
                                              class="input-readonly bg-warning"
                                              type="number"
                                              step="any"
                                              readonly="readonly"
                                              :id="`np${payments.id}`"
                                              :value="payments.amountpayable"
                                              placeholder="0"
                                              disabled
                                            />
                                            </td>
                                        </tr>
              
                                      </table>

   
                 
                          </div>
                          </div>
                        </div>
                        <!-- detail table ends -->


                    </div>
                </div>
            </div>  
        </div>
      </div>
    </main>
  </div>
</template>

<script>



export default {
  
  name: "Sales",
  data() {
    return {
      opensale: false,
      opensaledetail: false,
      openpayment:false,
      opencrates:false,
      token: "",
      activedate: "",

      shopName: '',
      noShopName: false,
      shopAddress: '',
      netPayable: 0,

      acData: [],
      itemData: [],
      detailData: [],
      paymentData: [],
      crateData: [],
      closingData: [],

      selectedAccountId:"",

      totalsale:0,
      totaladditions:0,
      totaldeductions:0,
      counter:0,
     

    };
  },
  mounted(){
          this.token = localStorage.getItem(this.$ut)
        //   this.uid = JSON.parse(this.dec(localStorage.getItem(this.$ud))).id;
          this.activedate = localStorage.getItem('ad');
          this.loadLedgers();
      
  },

  methods: {
    
    updateSale(id){
        let $qty = document.getElementById('q'+id).value;
        let $rate = document.getElementById('r'+id).value;
        let $damageqty = 0; //document.getElementById('d'+id).value;
        let $returnqty = 0; //document.getElementById('re'+id).value;
        
        let $subtotal = (parseFloat($qty) - (parseFloat($damageqty) + parseFloat($returnqty))) * parseFloat($rate);
 
        const params = JSON.stringify({
          id: id,
          acid : this.selectedAccountId.id,
          qty: $qty,
          rate: $rate,
          returnqty : $returnqty,
          damageqty: $damageqty,
          subtotal: $subtotal,
          activedate : this.activedate,
        })


        this.$df.post(this.$au + "dailytrans",params,{headers : {"Authorization" : `Bearer ${this.token}`}}
        ).then(res => {
          this.itemData = res.data.data;
          // this.totalsale = res.data.totalsale
          this.paymentData = res.data.paymentdata
          this.closingData = res.data.closingdata
          this.totalsale  = res.data.paymentdata[0].amountpayable


        }).catch(err =>{
                        console.log(err)
                    })
        
    },
    
    updateDetail(id){
        let $description = document.getElementById('dd'+id).value;
        let $amtin = document.getElementById('ai'+id).value;
        let $amtout = document.getElementById('ao'+id).value;
        let $iscf = (document.getElementById('iscf'+id).checked)?1:0;
        let $isdaily = (document.getElementById('isdaily'+id).checked)?1:0;
         
 
        const params = JSON.stringify({
          id: id,
          acid : this.selectedAccountId.id,
          description: $description,
          amtin: $amtin,
          amtout : $amtout,
          iscf: $iscf,
          isdaily: $isdaily,
          activedate : this.activedate,
        })

        this.$df.post(this.$au + "dailydetails",params,{headers : {"Authorization" : `Bearer ${this.token}`}}
        ).then(res => {
          
          this.detailData = res.data.detaildata;
          this.totaladditions = res.data.totaladditions
          this.totaldeductions = res.data.totaldeductions
          this.paymentData = res.data.paymentdata
          this.totalsale  = res.data.paymentdata[0].amountpayable

          

        }).catch(err =>{
            console.log(err)
        })
        
    },

    updatePayments(id){
        let $openingbal = document.getElementById('ob'+id).value;
        let $saletotal = document.getElementById('st'+id).value;
        let $otheradditions = document.getElementById('oa'+id).value;
        let $otherdeductions = document.getElementById('od'+id).value;
        let $amountpaid = document.getElementById('p'+id).value;

        let $amountpayable = (parseFloat($openingbal) + parseFloat($saletotal) + (parseFloat($otheradditions) - parseFloat($otherdeductions))) - parseFloat($amountpaid);        
        
        const params = JSON.stringify({
          id: id,
          acname: this.selectedAccountId.text,
          acid: this.selectedAccountId.id,
          actype: this.selectedAccountId.type,
          activedate: this.activedate,
          openingbal: $openingbal,
          amountpaid: $amountpaid,
          amountpayable : $amountpayable,
        })


        this.$df.post(this.$au + "dailypayments",params,{headers : {"Authorization" : `Bearer ${this.token}`}}
        ).then(res => {
          
          this.paymentData = res.data.paymentdata;
          this.totalsale  = res.data.paymentdata[0].amountpayable


        }).catch(err =>{
            console.log(err)
        })
        
    },

    updateCrates(id){
        let $openingbal = document.getElementById('ob'+id).value;
        let $issueqty = document.getElementById('iq'+id).value;
        let $returnqty = document.getElementById('rq'+id).value;
        let $closingbal = parseFloat($openingbal) + parseFloat($issueqty) - parseFloat($returnqty);
         
 
        const params = JSON.stringify({
          id: id,
          acid : this.selectedAccountId.id, 
          issueqty: $issueqty,
          returnqty : $returnqty,
          closingbal: $closingbal,
          activedate : this.activedate,
        })

        this.$df.post(this.$au + "dailycrates",params,{headers : {"Authorization" : `Bearer ${this.token}`}}
        ).then(res => {
          
          this.crateData = res.data.cratedata
          

        }).catch(err =>{
            console.log(err)
        })
        
    },


// toggle input slide outs ------------------------------

    togglesaledetail() {
      this.opensaledetail = !this.opensaledetail;
      this.opensale = false;
      this.openpayment = false;
      this.opencrates = false;
      
    },
    togglepayment() {
      this.openpayment = !this.openpayment;
      this.opensaledetail = false;
      this.opensale = false;
      this.opencrates = false;

    },
   togglecrates() {
      this.opencrates = !this.opencrates;
      this.opensaledetail = false;
      this.opensale = false;
      this.openpayments = false;
    },
   
// toggle end slide outs ----------------------------------


    loadLedgers(){
          this.$df.get(this.$au + "ledgers", {headers : {"Authorization" : `Bearer ${this.token}`}}
          ).then(res =>{
              // this.acData = res;
                this.acData = res.data.data
            }).catch(err =>{
                console.log(err)
            })
    },
    loadEntryTable(){
          const params = JSON.stringify({
            activedate: this.activedate,
            acid: this.selectedAccountId.id
          })


              let loader = this.$loading.show({
                    // Optional parameters
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                    onCancel: this.onCancel,
                    color: '#f00',
                    loader: 'spinner', //spinner, bars, dots
                    width: 128,
                    height: 128,
                    opacity: .5,
                });





          this.$df.post(this.$au + "dailytransfetch",params,{headers : {"Authorization" : `Bearer ${this.token}`}}
          ).then(res =>{
              // this.acData = res;
            this.itemData = res.data.data
            this.detailData = res.data.detaildata
            this.paymentData = res.data.paymentdata
            this.crateData = res.data.cratedata
            this.closingData = res.data.closingdata

            // this.totalsale = res.data.totalsale
            this.totaladditions = res.data.totaladditions
            this.totaldeductions = res.data.totaldeductions  
            this.totalsale  = res.data.paymentdata[0].amountpayable          

            loader.hide()

            }).catch(err =>{
                loader.hide()
                console.log(err)
            })




    },
    getClosingData(itemname,brand){
      return this.closingData.find((item) => item.itemname === itemname && item.brand===brand).qty
      
    }    
  },
};
</script>

<style scoped>
@media only screen and (max-width: 374px)  {
 
  .btn-custom-size{
    width:200px;
    font-size:14px;
    margin-bottom: 0;
    margin-top:0;
  }
  .slidein {
    max-width: 100vw;
  }
   .table{
    font-size:10px;
    line-height:10px;
 
  }
  .table tr td{
    padding: 1px;
    margin:1px;
  }

  
  small {
  font-size: 10px;
}
}

@media (min-width: 375px) and (max-width: 899px) {
  .btn-custom-size{
    width:200px;
    font-size:14px;
    margin-bottom: 0;
    margin-top:0;
   
  }

  .slidein {
    max-width: 95vw;
  }  
   .table{
    font-size:10px;
    line-height:20px;
 
  }

  .table tr td{
    padding: 2px;
    margin:5px;
  }
  small {
  font-size: 10px;
}
}

@media (min-width: 900px) and (max-width: 1029px) {
  .btn-custom-size{
    width:200px;
    font-size:14px;
    margin-bottom: 0;
    margin-top:0;
   
  }

  .slidein {
    max-width: 80vw;
  }  
   .table{
    font-size:14px;
    line-height:20px;
 
  }

  .table tr td{
    padding: 2px;
    margin:5px;
  }
  small {
  font-size: 10px;
}
}

@media only screen and (min-width: 1030px) and (max-width: 1439px) {
  .btn-custom-size{
    width:200px;
    font-size:15px;
   
  }
  .slidein {
    max-width: 62vw;
    font-size:12px;
  }
  small {
  font-size: 12px;
}

}

@media only screen and (min-width: 1440px) and (max-width: 3840px) {
  .btn-custom-size{
    width:200px;
    font-size:15px;
   
  }
  .slidein {
    max-width: 55vw;
    font-size:12px;
  }
  small {
  font-size: 12px;
}

}

.slidein {
  padding: 2em 3em;
  position: fixed;
  z-index: 100;
  top: 0;
  right: -100%;
  color: #fff;
  background: rgb(1, 38, 80);
  height: 100%;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
  transition: all 0.5s ease-in-out;
}

/* Set positioning back to 0 when toggled opened */
.open {
  right: 0;
}

/* add a close button in case toggle button is hidden on smaller screens */
.close-btn {
  border: none;
  font-weight: bold;
  font-size: 2em;
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.5em;
}

.toggle {
  margin: 1em;
}

button {
  padding: 0.5em 1em;
  font-size: 1.1em;
}




input[type="number"]{
  width: 100%;
  background:#fff;
  color:#000;
  
}

input[type="text"]{
  width: 100%;
  background:#fff;
  color:#000;
}


.input-readonly{
  color:black;
  background:rgb(217, 255, 1);
  border:black;
}

/* Removes the clear button from date inputs */
input[type="date"]::-webkit-clear-button {
    display: none;
}

/* Removes the spin button */
input[type="date"]::-webkit-inner-spin-button { 
    display: none;
}

/* Always display the drop down caret */
input[type="date"]::-webkit-calendar-picker-indicator {
    color: #2c3e50;
}

/* A few custom styles for date inputs */
input[type="date"] {
    appearance: none;
    -webkit-appearance: none;
    color: #95a5a6;
    font-family: "Helvetica", arial, sans-serif;
    font-size: 18px;
    border:1px solid #ecf0f1;
    background:#ecf0f1;
    padding:5px;
    display: inline-block !important;
    visibility: visible !important;
}

input[type="date"], focus {
    color: #95a5a6;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
}

table {
  table-layout: fixed ;
  width: 100% ;
}
td {
  width: 25% ;
} 


</style>