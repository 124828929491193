<template>
  <div>
      
    <a
      href="javascript:void(0)"
      class="text-light"
      data-toggle="modal"
      data-target="#test1"
    >
      <span class="material-icons" style="font-size: 32px"> update </span>
      {{showDenom}}
    </a>

    <!-- Payments Modal -->
    <div  class="modal fade" id="test1" tabindex="-1" role="dialog" aria-labelledby="dateModalLabel" aria-hidden="true" >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="exampleModalLabel" class="modal-title">Add Payment</h5>
            <button type="button" data-dismiss="modal" aria-label="Close" class="close">
                <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form ngnativevalidate="" class="ng-dirty ng-touched ng-valid">
            <div class="modal-body">
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                  <div class="form-floating">
                    <select id="paymentType" name="paymentType" required="" class="form-select form-control">
                      <option value="CASH">CASH</option>
                      <option value="CHEQUE">CHEQUE</option>
                      <option value="NET BANKING">NET BANKING</option>
                      <option value="UPI">UPI</option>
                      <option value="EMI">EMI</option>
                      <option value="OTHER">OTHER</option>
                      <!---->
                    </select>
                    <label for="paymentType">Payment Type</label>
                  </div>
                </div>

                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                  <div class="form-floating">
                    <input
                      type="number"
                      name="amount"
                      placeholder=""
                      min="0"
                      step="1.0"
                      required=""
                      class="form-control"
                      v-model="amount"
                      
                    />
                    <label for="floatingInput">Amount</label>
                  </div>
                </div>

                <div
                  class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3"
                >
                  <div class="form-floating">
                    <input
                      type="text"
                      id="remarks"
                      name="remarks"
                      placeholder="Description"
                      class="form-control"
                      v-model="remarks"
                     
                    />
                    <label for="floatingInput">Description</label>
                  </div>
                </div>
                <div
                  class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mt-3"
                >
                  <div class="form-check">
                    <input
                      type="checkbox"
                      value=""
                      class="form-check-input"
                      v-model="showDenom"
                      
                    />
                    <label for="flexCheckDefault" class="form-check-label">
                      Show Cash Denomination
                    </label>
                  </div>
                </div>
                <!---->
              </div>
              <div class="row mt-3" v-if="showDenom">
                <div
                  class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-3"
                >
                  <div class="form-floating">
                    <input
                      type="text"
                      id="note2000"
                      name="note2000"
                      placeholder=""
                      class="form-control"
                      v-model="note2000"
                    />
                    <label for="floatingInput">₹ 2000 Note(s)</label>
                  </div>
                </div>
                <div
                  class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-3"
                >
                  <div class="form-floating">
                    <input
                      type="text"
                      id="note500"
                      name="note500"
                      placeholder=""
                      class="form-control"
                      v-model="note500"
                    />
                    <label for="floatingInput">₹ 500 Note(s)</label>
                  </div>
                </div>
                <div
                  class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-3"
                >
                  <div class="form-floating">
                    <input
                      type="text"
                      id="note200"
                      name="note200"
                      placeholder=""
                      class="form-control"
                      v-model="note200"
                    />
                    <label for="floatingInput">₹ 200 Note(s)</label>
                  </div>
                </div>
                <div
                  class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-3"
                >
                  <div class="form-floating">
                    <input
                      type="text"
                      id="note100"
                      name="note100"
                      placeholder=""
                      class="form-control"
                      v-model="note100"
                    />
                    <label for="floatingInput">₹ 100 Note(s)</label>
                  </div>
                </div>
                <div
                  class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-3"
                >
                  <div class="form-floating">
                    <input
                      type="text"
                      id="note50"
                      name="note50"
                      placeholder=""
                      class="form-control"
                      v-model="note50"
                    /><label for="floatingInput">₹ 50 Note(s)</label>
                  </div>
                </div>
                <div
                  class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-3"
                >
                  <div class="form-floating">
                    <input
                      type="text"
                      id="note20"
                      name="note20"
                      placeholder=""
                      class="form-control"
                      v-model="note20"
                    /><label for="floatingInput">₹ 20 Note(s)</label>
                  </div>
                </div>
                <div
                  class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-3"
                >
                  <div class="form-floating">
                    <input
                      type="text"
                      id="note10"
                      name="note10"
                      placeholder=""
                      class="form-control"
                      v-model="note10"
                    /><label for="floatingInput">₹ 10 Note(s)</label>
                  </div>
                </div>
                <div
                  class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-3"
                >
                  <div class="form-floating">
                    <input
                      type="text"
                      id="note5"
                      name="note5"
                      placeholder=""
                      class="form-control"
                      v-model="note5"
                    /><label for="floatingInput">₹ 5 Note(s)</label>
                  </div>
                </div>
                <div
                  class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-3"
                >
                  <div class="form-floating">
                    <input
                      type="text"
                      id="note2"
                      name="note2"
                      placeholder=""
                      class="form-control"
                      v-model="note2"
                    /><label for="floatingInput">₹ 2 Coin(s)</label>
                  </div>
                </div>
                <div
                  class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-3"
                >
                  <div class="form-floating">
                    <input
                      type="text"
                      id="note1"
                      name="note1"
                      placeholder=""
                      class="form-control"
                      v-model="note1"
                    /><label for="floatingInput">₹ 1 Coin(s)</label>
                  </div>
                </div>
              </div>
              <!---->
            </div>
            <div class="modal-footer">
              <button type="button" data-dismiss="modal" class="btn btn-secondary btn-lg">Close</button>
              <button type="submit" class="btn btn-success btn-lg">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- Payments Modal Ends -->
  </div>
</template>

<script>
export default {
  name: "Test",
  data() {
    return {
      token: "",
      amount:0,
      remarks:'',
      note1:0,
      note2:0,
      note5:0,
      note10:0,
      note20:0,
      note50:0,
      note100:0,
      note200:0,
      note500:0,
      note2000:0,
      showDenom:false,

    };
  },
  mounted() {
    this.token = localStorage.getItem(this.$ut);
  },
  methods: {},
};
</script>






