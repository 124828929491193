<template>
    <div>
        <!-- Button trigger modal -->
        <a href="javascript:void(0)" style="text-decoration:none" data-toggle="modal" data-target="#posModal">  
            <div class="icon icon-40 rounded-circle mb-1 bg-default-light text-default">
                <span class="material-icons">account_circle</span>
            </div>
            <p class="text-white"><small>POS</small></p>
        </a>

        <!-- Payments Modal -->
        <div  class="modal fade" id="posModal" tabindex="-1" role="dialog" aria-labelledby="posModalLabel" aria-hidden="true" >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Point of Sale</h5>
                        <button type="button" data-dismiss="modal" aria-label="Close" class="close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="form-floating mb-3">
                                    <select id="itemName" name="itemName" required="" class="form-select form-control" v-model="selecteditem">
                                        <option value="0">Select Item</option>
                                        <option v-for="item in itemData" :value="{nm:item.itemname,brand:item.shortname,seqno:item.seqno}" :key="item.id">{{item.itemname}} - {{item.shortname}}</option>
                                    </select>
                                </div>
                            </div>           
                        </div>
                        <div class="row mb-3">
                             <table class="table">
                                 <tr>
                                     <th>Qty</th>
                                     <th>Rate</th>
                                     <th>Amount</th>
                                 </tr>
                                 <tr>
                                     <td>                                    <input
                                        type="number"
                                        name="qty"
                                        min="0"
                                        step="1.0"
                                        class="form-control"
                                        v-on:blur="calctot"
                                        v-model="qty"
                                        @focus="$event.target.select()"
                                    /></td>
                                     <td>                                    <input
                                        type="number"
                                        name="rate"
                                        min="0"
                                        step="1.0"
                                        class="form-control"
                                        v-on:blur="calctot"
                                        v-model="rate"
                                        @focus="$event.target.select()"
                                    /></td>
                                     <td>                                   <input
                                        type="number"
                                        name="subtotal"
                                        min="0"
                                        step="1.0"
                                        class="form-control"
                                        v-model="subtotal"
                                        @focus="$event.target.select()"
                                    /></td>

                                 </tr>
                             </table>   


                            <div class="col-12 text-left ">
                                <div class="form-check">
                                    <input
                                        type="checkbox"
                                        value=""
                                        class="form-check-input "
                                        v-model="showPayment"
                                    />
                                    <label for="flexCheckDefault" class="form-check-label">
                                        Collect payment for the sale/purchase
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3" v-if="showPayment">
                            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                <div class="form-floating">
                                    <select id="paymentType" name="paymentType" required="" class="form-select form-control" v-model="paymentmode">
                                        <option value="CASH">CASH</option>
                                        <option value="CHEQUE">CHEQUE</option>
                                        <option value="NET BANKING">NET BANKING</option>
                                        <option value="UPI">UPI</option>
                                        <option value="EMI">EMI</option>
                                        <option value="OTHER">OTHER</option>
                                        <!---->
                                    </select>
                                </div>
                            </div>

                            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                <div class="form-floating">
                                    <input
                                        type="number"
                                        name="amount"
                                        min="0"
                                        step="1.0"
                                        class="form-control"
                                        v-model="amount"
                                        :readonly="showDenom"
                                        @focus="$event.target.select()"
                                    />
                                </div>
                            </div>

                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3">
                            <div class="form-floating">
                                <input type="text" id="remarks" name="remarks" placeholder="Description" class="form-control" v-model="remarks" />
                            </div>
                        </div>
                        <div
                            class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3" v-if="paymentmode == 'CASH'" >
                            <div class="form-check">
                                <input type="checkbox" value="" class="form-check-input" v-model="showDenom" />
                                <label for="flexCheckDefault" class="form-check-label">
                                    Show Cash Denomination
                                </label>
                            </div>
                        </div>
                        <!---->
                        </div>
                        <div class="row mt-3" v-if="showDenom">
                        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-xs-4 mb-3">
                            <div class="form-floating">
                                <input type="number" id="note2000"  placeholder="" class="form-control" v-model="note2000" v-on:keyup="denomtotal" @focus="$event.target.select()" />
                                <label for="floatingInput">₹ 2000 Note(s)</label>
                            </div>
                        </div>
                        <div
                            class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-xs-4 mb-3">
                            <div class="form-floating">
                                <input type="number" id="note500" class="form-control" v-model="note500" v-on:keyup="denomtotal" @focus="$event.target.select()"
                                />
                                <label for="floatingInput">₹ 500 Note(s)</label>
                            </div>
                        </div>
                        <div
                            class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-xs-4 mb-3"
                        >
                            <div class="form-floating">
                            <input
                                type="number"
                                id="note200"
                                placeholder=""
                                class="form-control"
                                v-model="note200"
                                v-on:keyup="denomtotal"
                                @focus="$event.target.select()"
                            />
                            <label for="floatingInput">₹ 200 Note(s)</label>
                            </div>
                        </div>
                        <div
                            class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-xs-4 mb-3"
                        >
                            <div class="form-floating">
                            <input
                                type="number"
                                id="note100"
                                placeholder=""
                                class="form-control"
                                v-model="note100"
                                v-on:keyup="denomtotal"
                                @focus="$event.target.select()"
                            />
                            <label for="floatingInput">₹ 100 Note(s)</label>
                            </div>
                        </div>
                        <div
                            class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-xs-4 mb-3"
                        >
                            <div class="form-floating">
                            <input
                                type="number"
                                id="note50"
                                placeholder=""
                                class="form-control"
                                v-model="note50"
                                v-on:keyup="denomtotal"
                                @focus="$event.target.select()"
                            /><label for="floatingInput">₹ 50 Note(s)</label>
                            </div>
                        </div>
                        <div
                            class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-xs-4 mb-3"
                        >
                            <div class="form-floating">
                            <input
                                type="number"
                                id="note20"
                                placeholder=""
                                class="form-control"
                                v-model="note20"
                                v-on:keyup="denomtotal"
                                @focus="$event.target.select()"
                            /><label for="floatingInput">₹ 20 Note(s)</label>
                            </div>
                        </div>
                        <div
                            class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-xs-4 mb-3"
                        >
                            <div class="form-floating">
                            <input
                                type="number"
                                id="note10"
                                placeholder=""
                                class="form-control"
                                v-model="note10"
                                v-on:keyup="denomtotal"
                                @focus="$event.target.select()"
                            /><label for="floatingInput">₹ 10 Note(s)</label>
                            </div>
                        </div>
                        <div
                            class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-xs-4 mb-3"
                        >
                            <div class="form-floating">
                            <input
                                type="number"
                                id="note5"
                                class="form-control"
                                v-model="note5"
                                v-on:keyup="denomtotal"
                                @focus="$event.target.select()"
                            /><label for="note5">₹ 5 Note(s)</label>
                            </div>
                        </div>
                        <div
                            class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-xs-4 mb-3"
                        >
                            <div class="form-floating">
                            <input
                                type="number"
                                id="note2"
                                class="form-control"
                                v-model="note2"
                                v-on:keyup="denomtotal"
                                @focus="$event.target.select()"
                            /><label for="floatingInput">₹ 2 Coin(s)</label>
                            </div>
                        </div>
                        <div
                            class="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-xs-4 mb-3"
                        >
                            <div class="form-floating">
                            <input
                                type="number"
                                id="note1"
                                class="form-control"
                                v-model="note1"
                                v-on:keyup="denomtotal"
                                @focus="$event.target.select()"
                            /><label for="floatingInput">₹ 1 Coin(s)</label>
                            </div>
                        </div>
                        </div>

                        <div class="row">
                            <div class="col-3">
                                <button @click="updateSale"  class="btn btn-danger" style="width:100%">SELL</button>
                            </div>
                            <div class="col-3">
                                <button @click="updatePurchase" data-dismiss="modal" class="btn btn-success" style="width:100%">BUY</button>
                            </div>
                            <div class="col-6 text-right">
                                <button type="button" data-dismiss="modal" class="btn btn-secondary">Close</button>
                            </div>
                        </div>
                    </div>
                 </div>
            </div>
        </div>
        <!-- Payments Modal Ends --> 
    </div>
</template>

<script>
export default {
  name: 'PosModal',
  data(){
      return{
        sacid:0,
        pacid:0,
        token:'',
        activedate:'',
        itemData:[],
        
        
        qty: 0,
        rate: 0,
        selecteditem:null,
        subtotal:0,

        paymentmode:'',
        amount:0,
        remarks:'',
        note1:0,
        note2:0,
        note5:0,
        note10:0,
        note20:0,
        note50:0,
        note100:0,
        note200:0,
        note500:0,
        note2000:0,
        showPayment:false,
        showDenom:false,


      }
  },
   mounted(){
        this.token = localStorage.getItem(this.$ut);
        this.activedate = localStorage.getItem('ad');
        this.sacid = JSON.parse(this.dec(localStorage.getItem(this.$ud))).sacid;
        this.pacid = JSON.parse(this.dec(localStorage.getItem(this.$ud))).pacid;
        this.sacname = JSON.parse(this.dec(localStorage.getItem(this.$ud))).sacname;
        this.pacname = JSON.parse(this.dec(localStorage.getItem(this.$ud))).pacname;
        this.loadItems();

      
  },
  methods:{
      calctot(){
            this.subtotal = Number(this.qty) * Number(this.rate)
      },
       updateSale(){
        let loader = this.$loading.show({
                // Optional parameters
                container: this.fullPage ? null : this.$refs.formContainer,
                canCancel: false,
                onCancel: this.onCancel,
                color: '#f00',
                loader: 'spinner', //spinner, bars, dots
                width: 128,
                height: 128,
                opacity: .5,
            });

        

        const params = JSON.stringify({
          acid : this.sacid,
          qty: this.qty,
          rate: this.rate,
          seqno: this.selecteditem.seqno,
          itemname: this.selecteditem.nm,
          brand: this.selecteditem.brand,
          acname: this.sacname,
          subtotal: this.subtotal,
          activedate : this.activedate,
        })
        console.log(params)

        this.$df.post(this.$au + "dailytranspos",params,{headers : {"Authorization" : `Bearer ${this.token}`}}
        ).then(() => {
            loader.hide()
            this.$alert("Item sold and recorded in ("+this.sacname+")")  
            this.qty = 0
            this.rate=0
            this.subtotal=0

            // this.itemData = res.data.data;
            // this.totalsale = res.data.totalsale
            // this.paymentData = res.data.paymentdata
            // this.closingData = res.data.closingdata
            
        }).catch(err =>{
            loader.hide()
            console.log(err)
        })


    },

    updatePurchase(){
        let loader = this.$loading.show({
                // Optional parameters
                container: this.fullPage ? null : this.$refs.formContainer,
                canCancel: false,
                onCancel: this.onCancel,
                color: '#f00',
                loader: 'spinner', //spinner, bars, dots
                width: 128,
                height: 128,
                opacity: .5,
            });

        

        const params = JSON.stringify({
          acid : this.pacid,
          qty: this.qty,
          rate: this.rate,
          seqno: this.selecteditem.seqno,
          itemname: this.selecteditem.nm,
          brand: this.selecteditem.brand,
          acname: this.pacname,
          subtotal: this.subtotal,
          activedate : this.activedate,
        })

        this.$df.post(this.$au + "dailytranppos",params,{headers : {"Authorization" : `Bearer ${this.token}`}}
        ).then(() => {

            loader.hide()
            this.$alert("Item purchased and recorded in ("+this.pacname+")")  
            this.qty = 0
            this.rate=0
            this.subtotal=0

        }).catch(err =>{
            loader.hide()
            console.log(err)
        })


    },

    loadItems(){
        let loader = this.$loading.show({
                // Optional parameters
                container: this.fullPage ? null : this.$refs.formContainer,
                canCancel: false,
                onCancel: this.onCancel,
                color: '#f00',
                loader: 'spinner', //spinner, bars, dots
                width: 128,
                height: 128,
                opacity: .5,
            });

         this.$df.get(this.$au + "items",{headers : {"Authorization" : `Bearer ${this.token}`}}
            ).then(res =>{
                
                this.itemData = res.data.data
                loader.hide()

            }).catch(err =>{
                loader.hide()
                console.log(err)
            })
    }
  }
  
}
</script>