<template>
    <div>
        <main class="flex-shrink-0 main" style="margin-bottom:10vh;">
        <div class="main-container p-2">
                <div class="container">
                    <div class="card">
                        <div class="card-header">
                            <h6 class="mb-0">App Settings</h6>
                        </div>
                        <div class="card-body px-0 pt-0">
                            <div class="list-group list-group-flush border-top border-color">

                                <a href="/itemmaster" class="list-group-item list-group-item-action border-color">
                                    <div class="row">
                                        <div class="col-auto">
                                            <div class="avatar avatar-50 bg-default-light text-default rounded">
                                                <span class="material-icons">category</span>
                                            </div>
                                        </div>
                                        <div class="col align-self-center pl-0">
                                            <h6 class="mb-1">Item Master</h6>
                                            <p class="text-secondary">Add, update, delete Items</p>
                                        </div>
                                    </div>
                                </a>
                                
                                <a href="/accountssale" class="list-group-item list-group-item-action border-color">
                                    <div class="row">
                                        <div class="col-auto">
                                            <div class="avatar avatar-50 bg-default-light text-default rounded">
                                                <span class="material-icons">account_balance_wallet</span>
                                            </div>
                                        </div>
                                        <div class="col align-self-center pl-0">
                                            <h6 class="mb-1">Sales Accounts</h6>
                                            <p class="text-secondary">Manage Sales Accounts</p>
                                        </div>
                                    </div>
                                </a>
                                <a href="/accountspurchase" class="list-group-item list-group-item-action border-color">
                                    <div class="row">
                                        <div class="col-auto">
                                            <div class="avatar avatar-50 bg-default-light text-default rounded">
                                                <span class="material-icons">account_balance_wallet</span>
                                            </div>
                                        </div>
                                        <div class="col align-self-center pl-0">
                                            <h6 class="mb-1">Purchase Accounts</h6>
                                            <p class="text-secondary">Manage Purchase Accounts</p>
                                        </div>
                                    </div>
                                </a>


 

                                <a href="/profile" class="list-group-item list-group-item-action border-color">
                                    <div class="row">
                                        <div class="col-auto">
                                            <div class="avatar avatar-50 bg-default-light text-default rounded">
                                                <span class="material-icons">contact_mail</span>
                                            </div>
                                        </div>
                                        <div class="col align-self-center pl-0">
                                            <h6 class="mb-1">Profile</h6>
                                            <p class="text-secondary">Manage Profile</p>
                                        </div>
                                    </div>
                                </a>
                            
                            <a href="/changepassword" class="list-group-item list-group-item-action border-color">
                                    <div class="row">
                                        <div class="col-auto">
                                            <div class="avatar avatar-50 bg-default-light text-default rounded">
                                                <span class="material-icons">vpn_key</span>
                                            </div>
                                        </div>
                                        <div class="col align-self-center pl-0">
                                            <h6 class="mb-1">Password Settings</h6>
                                            <p class="text-secondary">Change your Password</p>
                                        </div>
                                    </div>
                                </a>


                                <a href="/backupdata" class="list-group-item list-group-item-action border-color">
                                    <div class="row">
                                        <div class="col-auto">
                                            <div class="avatar avatar-50 bg-default-light text-default rounded">
                                                <span class="material-icons">category</span>
                                            </div>
                                        </div>
                                        <div class="col align-self-center pl-0">
                                            <h6 class="mb-1">Backup Data</h6>
                                            <p class="text-secondary">Download and Backup data</p>
                                        </div>
                                    </div>
                                </a>

                                <a href="/restoredata" class="list-group-item list-group-item-action border-color">
                                    <div class="row">
                                        <div class="col-auto">
                                            <div class="avatar avatar-50 bg-default-light text-default rounded">
                                                <span class="material-icons">category</span>
                                            </div>
                                        </div>
                                        <div class="col align-self-center pl-0">
                                            <h6 class="mb-1">Restore Data</h6>
                                            <p class="text-secondary">Restore your data</p>
                                        </div>
                                    </div>
                                </a>
                                <opening-bal-modal></opening-bal-modal>
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import OpeningBalModal from './OpeningBalModal.vue'

export default {
    name: "AppSettings",
    components: {OpeningBalModal
    },
}
</script>