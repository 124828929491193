<template>
  <div id="app">
    <loader/>
    <div v-if="!$route.meta.noHeader">
      <app-head/>
    </div>
    <router-view />
    <div v-if="!$route.meta.noMenu">
      <AppMenu/>
    </div>
    
  </div>
</template>

<script>

import Loader from './components/Loader.vue'
import AppMenu from './components/AppMenu.vue'
import AppHead from './components/AppHead.vue'



export default {
  components: { Loader, AppMenu, AppHead},
  name: 'App',
}
</script>

<style>

</style>
