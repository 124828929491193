<template>
    <div>
         <!-- Begin page content -->
    <main class="flex-shrink-0 main has-footer">

        
        <div class="container h-100 text-white">
            <div class="row h-100">
                <div class="col-12 align-self-center mb-4">
                    <div class="row justify-content-center">
                        <div class="col-11 col-sm-7 col-md-6 col-lg-5 col-xl-4">
                            <h2 class="font-weight-normal mb-5">Daily Inventory <small>v1.0</small></h2>
                            <h6>{{registerto}}</h6>
                            
                            <div class="form-group float-label position-relative">
                                <input type="date" placeholder="dd-mm-yyyy" class="form-control text-white" v-model="activedate">
                            </div>

                             <div class="form-group float-label position-relative">
                                <input ref="useremail" type="password" class="form-control text-white" v-model="userpass" @keyup.enter="signin()" autofocus>
                                <label class="form-control-label text-white">Password</label>
                            </div> 
                            
                            <p class="text-right"><a href="/forgotpassword" class="text-white">Forgot Password?</a></p>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </main>

    <!-- footer-->
    <div class="footer no-bg-shadow py-3">
        <div class="row justify-content-center">
            <div class="col">
                <button v-on:click="signin()"  class="btn btn-default rounded btn-block">Login</button>
            </div>
        </div>
    </div>


    </div>
</template>

<script>


export default {
    
    name: 'Login',
    data(){
        return{
            userpass:'',
            activedate:'',
            registerto:'',
        }
       
    },
    mounted(){
        this.registerto = this.$registerto
        localStorage.removeItem(this.$ul);
        localStorage.removeItem(this.$ut);
        localStorage.removeItem(this.$ud);

        var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();

            today = yyyy + '-' + mm + '-' + dd;
            this.activedate = today;

        
    },
    methods:{
        signin(){
            
            localStorage.setItem('ad',this.activedate);

            const params = JSON.stringify({
                pass : this.userpass,
            })
         
              let loader = this.$loading.show({
                    // Optional parameters
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                    onCancel: this.onCancel,
                    color: '#f00',
                    loader: 'spinner', //spinner, bars, dots
                    width: 128,
                    height: 128,
                    opacity: .5,
                });


            this.$df.post(this.$au + 'authtoken',params)
            .then(res => {
                if(res.data.status){
                    localStorage.setItem(this.$ul,true);
                    localStorage.setItem(this.$ut,res.data.token);

                    localStorage.setItem(this.$ud,this.enc(JSON.stringify(res.data.user)));
                    window.location = '/';
                }else{
                    this.$alert(res.data.message)    
                }
                loader.hide()
            }).catch(err =>{
                loader.hide()
                console.log(err)
            })

        }
    }

}
</script>
