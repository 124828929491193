<template>
    <div>
         <!-- Begin page content -->
        <main class="flex-shrink-0 main has-footer">
            <!-- page content start -->
          
            <!-- <div class="container-fluid top-70 text-center mb-4">
                <div class="avatar avatar-140 rounded-circle mx-auto shadow">
                    <div class="background">
                        <img src="img/user1.png" alt="">
                    </div>
                </div>
            </div> -->

           
            <div class="main-container">
               
                <div class="container">
                    <!-- <div class="alert alert-success">
                        <b>Congratulations!</b> Your address has been saved to your adresss list successfully.
                    </div> -->
                    <div class="card">
                        <div class="card-header">
                            <h6 class="subtitle mb-0">
                                <div class="avatar avatar-40 bg-primary-light text-primary rounded mr-2"><span class="material-icons vm">add_location_alt</span></div>
                                Edit your Profile
                            </h6>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="form-group float-label col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <label class="text-muted">Address</label>
                                    <input type="text" id="ca" class="form-control" v-model="companyaddress">
                                                            
                                </div>
                                <div class="form-group float-label active col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <label class="text-muted" >City</label>
                                    <select class="form-control" id="cc" v-model="companycity">
                                        <option value="Dehradun" selected>Dehradun</option>
                                    </select>    
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group float-label col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12" >
                                    <label class="text-muted">Phone Number</label> 
                                    <input type="text" id="cp" class="form-control" v-model="companyphone" >
                                </div>
                                <div class="form-group float-label col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <label class="text-muted">E-Mail</label>                            
                                    <input type="text" id="ce" class="form-control" v-model="companyemail">
                                    
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group float-label col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <label class="text-muted">GSTIN</label>
                                    <input type="text" id="cg" class="form-control" v-model="companygstin">
                                    
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group float-label active col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <label class="text-muted" >Purchase Shop</label>
                                    <select class="form-control" id="purchaseshop" v-model="selectedpac">
                                        <option value="0">Select Account</option>
                                        <option :selected="selectedpac.id==data.id" v-for="data in ledgerpdata" :value="{id:data.id,name:data.acname}" :key="data.id">{{data.acname}}</option>
                                    </select>    
                                </div>
                                <div class="form-group float-label active col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <label class="text-muted" >Sales Shop</label>
                                    <select class="form-control" id="salesshop" v-model="selectedsac">
                                        <option value="0">Select Account</option>
                                        <option :selected="selectedsac.id==data.id" v-for="data in ledgersdata" :value="{id:data.id,name:data.acname}" :key="data.id">{{data.acname}}</option>
                                    </select>    
                                </div>
                            </div> 
                        </div>
                        
                        <div class="card-footer">
                            <button class="btn btn-block btn-default rounded" @click="updateProfile()" >Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </main>

    </div>
</template>

<script>
export default {
    name: 'Profile',
    data() {
        return {
            token:'',

            id: '',
            companyaddress: '',
            companyphone: '',
            companycity: '',
            companyemail: '',
            companygstin: '',
            selectedpac:null,
            selectedsac:null,

            ledgerpdata:[],
            ledgersdata:[],
        }
        
    },
    mounted(){
        this.token = localStorage.getItem(this.$ut);
        this.loadData();
    },
    methods: {
            loadData(){
                let profileData = JSON.parse(this.dec(localStorage.getItem(this.$ud)));
                this.id = profileData.id;
                this.companyaddress = profileData.companyaddress;
                this.companyphone = profileData.companyphone;
                this.companycity = profileData.companycity;
                this.companyemail = profileData.companyemail;
                this.companygstin = profileData.companygstin;
                this.selectedpac = {id:profileData.pacid,name:profileData.pacname};
                this.selectedsac = {id:profileData.sacid,name:profileData.sacname};

                let loader = this.$loading.show({
                    // Optional parameters
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                    onCancel: this.onCancel,
                    color: '#f00',
                    loader: 'spinner', //spinner, bars, dots
                    width: 128,
                    height: 128,
                    opacity: .5,
                });
                
                this.$df.get(this.$au + "ledgerp",{headers : {"Authorization" : `Bearer ${this.token}`}}
                ).then(res =>{
                    
                    this.ledgerpdata = res.data.data
                    
                }).catch(err =>{
                            loader.hide()
                            console.log(err)
                        })

                this.$df.get(this.$au + "ledgers",{headers : {"Authorization" : `Bearer ${this.token}`}}
                ).then(res =>{
                    
                    this.ledgersdata = res.data.data
                    loader.hide()
                }).catch(err =>{
                            loader.hide()
                            console.log(err)
                        })
            },
            
            updateProfile(){
            
            const params = JSON.stringify({
            id: this.id,
            companyaddress: this.companyaddress,
            companycity: this.companycity,
            companyphone : this.companyphone,
            companyemail: this.companyemail,
            companygstin: this.companygstin,
            pacid: this.selectedpac.id,
            pacname: this.selectedpac.name,
            sacid: this.selectedsac.id,
            sacname: this.selectedsac.name
            })
              let loader = this.$loading.show({
                    // Optional parameters
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                    onCancel: this.onCancel,
                    color: '#f00',
                    loader: 'spinner', //spinner, bars, dots
                    width: 128,
                    height: 128,
                    opacity: .5,
                });
            this.$df.post(this.$au + "users",params,{headers : {"Authorization" : `Bearer ${this.token}`}}
            ).then(res => {
            
            localStorage.setItem(this.$ud,this.enc(JSON.stringify(res.data.user)));
            this.ledgerpdata = res.data.ledgerpdata
            this.ledgersdata = res.data.ledgersdata

            this.loadData();
            loader.hide()

            }).catch(err =>{
                loader.hide()
                console.log(err)
            })
            
        }
    },

}
</script>