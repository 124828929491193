<template>
    <div>
        <main class="flex-shrink-0 main">
            <div class="main-container">
                <div class="container">
                    <div class="card">
                        <div class="card-header">
                            <h6 class="mb-0">Reports</h6>
                        </div>
                        <div class="card-body px-0 pt-0">
                            <div class="list-group list-group-flush border-top border-color">

                                <a href="/accountreport" class="list-group-item list-group-item-action border-color">
                                    <div class="row">
                                        <div class="col-auto">
                                            <div class="avatar avatar-50 bg-default-light text-default rounded">
                                                <span class="material-icons">category</span>
                                            </div>
                                        </div>
                                        <div class="col align-self-center pl-0">
                                            <h6 class="mb-1">Account Report</h6>
                                            <!-- <p class="text-secondary">Add, update, delete Items</p> -->
                                        </div>
                                    </div>
                                </a>

                                <a href="/itemreport" class="list-group-item list-group-item-action border-color">
                                    <div class="row">
                                        <div class="col-auto">
                                            <div class="avatar avatar-50 bg-default-light text-default rounded">
                                                <span class="material-icons">category</span>
                                            </div>
                                        </div>
                                        <div class="col align-self-center pl-0">
                                            <h6 class="mb-1">Item Report</h6>
                                           
                                        </div>
                                    </div>
                                </a>

                                 <a href="/brandreport" class="list-group-item list-group-item-action border-color">
                                    <div class="row">
                                        <div class="col-auto">
                                            <div class="avatar avatar-50 bg-default-light text-default rounded">
                                                <span class="material-icons">category</span>
                                            </div>
                                        </div>
                                        <div class="col align-self-center pl-0">
                                            <h6 class="mb-1">Brand Report</h6>
                                           
                                        </div>
                                    </div>
                                </a>

                                <a href="/stockreport" class="list-group-item list-group-item-action border-color">
                                    <div class="row">
                                        <div class="col-auto">
                                            <div class="avatar avatar-50 bg-default-light text-default rounded">
                                                <span class="material-icons">category</span>
                                            </div>
                                        </div>
                                        <div class="col align-self-center pl-0">
                                            <h6 class="mb-1">Stock Report</h6>
                                            
                                        </div>
                                    </div>
                                </a>

                                 <a href="/salereport" class="list-group-item list-group-item-action border-color">
                                    <div class="row">
                                        <div class="col-auto">
                                            <div class="avatar avatar-50 bg-default-light text-default rounded">
                                                <span class="material-icons">category</span>
                                            </div>
                                        </div>
                                        <div class="col align-self-center pl-0">
                                            <h6 class="mb-1">Sale Report</h6>
                                            
                                        </div>
                                    </div>
                                </a>

                                <a href="/purchasereport" class="list-group-item list-group-item-action border-color">
                                    <div class="row">
                                        <div class="col-auto">
                                            <div class="avatar avatar-50 bg-default-light text-default rounded">
                                                <span class="material-icons">category</span>
                                            </div>
                                        </div>
                                        <div class="col align-self-center pl-0">
                                            <h6 class="mb-1">Purchase Report</h6>
                                           
                                        </div>
                                    </div>
                                </a>

                               

                                
                                

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
export default {
    name: "Reports",
}
</script>