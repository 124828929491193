<template>
    <div>
         <!-- Begin page content -->
    <main class="flex-shrink-0 main has-footer">
        
        <div class="container h-100 text-white">
            <div class="row h-100">
                <div class="col-12 align-self-center mb-4">
                    <div class="row justify-content-center">
                        <div class="col-12 col-sm-8 col-md-7 col-lg-7 col-xl-6">
                            <h2 class="font-weight-normal mb-3">Forgot<br>your password?</h2>
                            <h4 class="mb-5">Please contact: {{email}}</h4>
                            <h5 ><u><a href="/login" class="text-warning">Go to Login</a></u></h5>
                            <!-- <div class="form-group float-label active">
                                <input type="text" v-model="email" class="form-control text-white" value="" >
                                <label class="form-control-label text-white">Username/Email</label>
                            </div> -->
                          
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </main>

    <!-- footer-->
        <!-- <div class="footer no-bg-shadow py-3">
            <div class="row justify-content-center">
                <div class="col">
                    <button v-on:click="sendpass()" class="btn btn-default rounded btn-block">Reset Password</button>
                </div>
            </div>
        </div> -->


    </div>
</template>

<script>

export default {
    name: 'ForgotPassword',
    data(){
      return{
          email:'sanjeev13singh@gmail.com'
      }
    },
 
}
</script>
