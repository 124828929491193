<template>
    <div>
      <main class="flex-shrink-0 main">
          <!-- {{dataList}} -->
        <div class="main-container p-4">
            <div class="container mb-2">
                <div class="card">
                    <div class="card-header">
                        <h6>Add Items</h6>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="form-group float-label active col-lg-4 col-sm-6">
                            <input type="text" class="form-control " value="" placeholder="Item Name" v-model="itemName" v-bind:class="[noItemName ? 'is-invalid' : 'is-valid']">
                            <p class="form-text invalid-feedback" v-show="noItemName">Please enter Correct Item Name</p>
                            </div>
                            <div class="form-group float-label active col-lg-4 col-sm-6">
                                <input type="text" class="form-control" value="" placeholder="Brand Name" v-model="itemShortName" v-bind:class="[noItemShortName ? 'is-invalid' : 'is-valid']">
                                <p class="form-text invalid-feedback" v-show="noItemShortName">Please enter Correct Brand Name</p>
                            </div>
                            <div class="form-group float-label active col-lg-4 col-sm-6">
                            <input type="number" inputmode="numeric" class="form-control" value="" placeholder="Crate Qty" v-model="itemCrateQty">
                            
                            </div>
                        </div>


 
                            <div class="row">
                                <div class="form-group float-label active col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <label class="text-muted">Background Color:</label>
                                <input type="color" class="form-control " v-model="bgColor">
                                </div>
                                <div class="form-group float-label active col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <label class="text-muted">Font Color:</label>
                                <input type="color" class="form-control " v-model="fontColor">
                                </div>
                            </div>
                       
                        
                         
                        <div class="form-group position-relative">
                            <button v-on:click="addItem()" class="btn btn-primary rounded btn-block">Add</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container mb-5 p-3">
                <h5 class="p-2">Items:</h5>
                <h6 class="p-2" v-if="dataList.length == 0">
                    No Records Found
                </h6>
                <div class="row">


                    <draggable v-model="dataList" group="itemssorted" @start="drag=true" @end="drag=false" @change="updateSequence">
                        <div class="pill" :style="`background-color:${data.bgcolor};color:${data.fontcolor}`"  v-for="data in dataList" :key="data.id">
                            <span class="text-left">{{data.itemname}} <br> <small>{{data.shortname}} {{ data.crateqty}}</small></span>
                            <span class="closebtn" v-on:click="deleteItem(data.id,data.itemname,data.shortname)"><b>X</b></span>
                        </div>
                    </draggable>




                </div>

                <!-- <div class="card mb-2" v-for="data in dataList" :key="data.id">
                    
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <h6 class="mb-1" :style="`background:${data.bgcolor};color:${data.fontcolor}`">{{data.itemname}}</h6>
                                <p class="text-secondary small">{{data.shortname}} {{ data.crateqty}} </p> 
                            </div>
                            <div class="col-auto">
                                <button v-on:click="editItem(data.id)"  class="btn btn-info rounded btn-md">Edit</button>
                            </div>
                            <div class="col-auto">
                                <button v-on:click="deleteItem(data.id)"  class="btn btn-danger rounded btn-md">Delete</button>
                            </div>
                        </div>
                        
                    </div>
                </div> -->
            </div>
        </div>




      </main>
    </div>    
</template>

<script>
import draggable from "vuedraggable";

export default {
    name: "ItemMaster",
    components:{
        draggable
    },
    data(){
        return{
            token: "",
            itemName: "",
            itemShortName: "",
            itemCrateQty: "",
        
            bgColor: "#ffffff",
            fontColor: "#000000",

            noItemName: false,
            noItemShortName: false,
            noItemCrateQty: false,
       
            message: "",
            dataList : [],
            activedate: '',

            itemssorted:[],
            }
        },
        mounted(){
            let loader = this.$loading.show({
                // Optional parameters
                container: this.fullPage ? null : this.$refs.formContainer,
                canCancel: false,
                onCancel: this.onCancel,
                color: '#f00',
                loader: 'spinner', //spinner, bars, dots
                width: 128,
                height: 128,
                opacity: .5,
            });


            this.token = localStorage.getItem(this.$ut);
            this.activedate = localStorage.getItem('ad');
            this.$df.get(this.$au + "items",{headers : {"Authorization" : `Bearer ${this.token}`}}
            ).then(res =>{
                
                this.dataList = res.data.data
                loader.hide()

            }).catch(err =>{
                loader.hide()
                console.log(err)
            })
        },
        methods: {
            validateForm(){
                this.noItemName = false;
                this.noItemShortName = false;  
                this.noItemCrateQty = false;
                
                if((this.itemName) && (this.itemShortName)){
                    if(this.itemName.length < 1 || this.itemName.length > 20){
                        this.noItemName = true;
                    }
                    if(this.itemShortName.length < 1 || this.itemShortName.length > 20){
                        this.noItemShortName = true;
                    }
                    if(this.itemCrateQty.length < 1){
                        this.noItemCrateQty = true;
                        this.itemCrateQty = 0;
                    }
                    if(this.noItemName || this.noItemShortName ){
                        return false;
                    }
                    return true;
                }
                this.noItemName = true;
                this.noItemShortName = true;
                
                return false;
            },
            
            addItem() {

            this.validation = this.validateForm()
            if(this.validation){
                const params = JSON.stringify({
                itemname: this.itemName,
                itemshortname: this.itemShortName,
                crateqty: this.itemCrateQty,                
                bgcolor: this.bgColor,
                fontcolor: this.fontColor,
                activedate: this.activedate,
                });
                
                this.$confirm('Are you sure you want to add this Item?','Confirm:').then(() =>{
                    let loader = this.$loading.show({
                        // Optional parameters
                        container: this.fullPage ? null : this.$refs.formContainer,
                        canCancel: false,
                        onCancel: this.onCancel,
                        color: '#f00',
                        loader: 'spinner', //spinner, bars, dots
                        width: 128,
                        height: 128,
                        opacity: .5,
                    });
                    
                    this.$df.post(this.$au + "items", params, { headers: {"Authorization" : `Bearer ${this.token}`}
                    }).then(res => {
                        
                        
                        this.itemShortName = "",
                        this.itemCrateQty = "",
                                   
                        this.dataList = res.data.data
                        loader.hide()
                    }).catch(err =>{
                        loader.hide()
                        console.log(err)
                    })
                })
            }else{
                this.$alert('error');
            }

            },

            deleteItem(id,itemname,brand){
                this.$confirm('Are you sure you want to delete '+itemname + '( '+ brand+')'+', doing so will result in data loss permanently?','Confirm:').then(() =>{

                    let loader = this.$loading.show({
                        // Optional parameters
                        container: this.fullPage ? null : this.$refs.formContainer,
                        canCancel: false,
                        onCancel: this.onCancel,
                        color: '#f00',
                        loader: 'spinner', //spinner, bars, dots
                        width: 128,
                        height: 128,
                        opacity: .5,
                    });

                    this.$df.delete(this.$au + 'items/' + id, {headers: {"Authorization" : `Bearer ${this.token}`}}
                    ).then(res =>{
                        this.message = res.data.message
                        this.$alert(this.message)
                        this.dataList = res.data.data
                        loader.hide()
                    }).catch(err =>{
                        loader.hide()
                        console.log(err)
                    })
                })
            },

            updateSequence(event){
                
                // console.log(event.moved.element.id)
                const params = JSON.stringify({
                    id: event.moved.element.id,
                    itemname: event.moved.element.itemname,
                    shortname: event.moved.element.shortname,
                    seqno: event.moved.newIndex,
                });

                this.$df.post(this.$au + "updatesequence", params, { headers: {"Authorization" : `Bearer ${this.token}`}
                }).then((res) => {
                    console.log(res)
                    
                }).catch(err =>{
                    console.log(err)
                })



            }
        }
}
</script>

<style>
    .invalidBorder{
        border : 1px solid red;
    }

    .pill {
        background-color: #ddd;
        border: none;
        color: black;
        padding: 5px;
        text-align: left;
        text-decoration: none;
        display: inline-block;
        margin: 2px 2px;
        border-radius: 16px;
    }

    .closebtn{
        width:24px;
        height:24px;
        float:right;
    }
    .closebtn:hover {
        color: #ff0000;
        cursor: pointer;
    }    
</style>