<template>
    <div>
        <main class="flex-shrink-0 main has-footer">
             
            <div class="container h-100">
                <div class="card mb-4">
                    <div class="card-header">
                        <h6 class="subtitle mb-0">
                            <div class="avatar avatar-40 bg-default-light text-default rounded mr-2"><span class="material-icons vm">lock</span></div>
                            Stock Report
                        </h6>
                    </div>
                    <div class="card-body">
                      
                        
                        <div class="row">
                        
                            <div class="form-group float-label active col-6" >
                                <label>For Date</label>
                                <input type="date" placeholder="dd-mm-yyyy" class="form-control" v-model="fordate">                           
                            </div>

                        
                        
                        </div>
                    </div>   
                     <div class="card-footer">
                        <div class="row">
                            <div class="col-6">
                                <button class="btn btn-block btn-success " @click="generateReport()">Generate Report</button>
                            </div>
                            
                            <div class="col-6">
                                <button class="btn btn-block btn-info " @click="generateSummary()">Generate Summary</button>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>

            <div class="container h-100" v-if="stockReportData.length > 0">
                <div class="card mb-4">
                    <div class="card-header">
                        <div class="row p-3">
                            <div class="col-auto">
                            <h6 class="subtitle mb-0">
                            <div class="avatar avatar-40 bg-default-light text-default rounded mr-2"><span class="material-icons vm">lock</span></div>
                                Stock Report
                            </h6>
                        
                            </div>
                            <div class="col">&nbsp;</div>
                            <div class="col-auto">
                                <button class="btn btn-block btn-warning " @click="xlsReport()">Xls</button> 
                                <button class="btn btn-block btn-warning " @click="exportReport()">Export</button> 
                            </div>
                        </div>
                        
                    </div>
                    <div class="card-body">
                        
                       <table class="table table-striped table-bordered" id="topdf">
                           <tr>
                               <th colspan="10"><h4>Stock Report:</h4></th>
                           </tr>
                           <tr>
                               <th colspan="10"> For : {{fordate}}</th>
                           </tr>                           
                            <tr>
                                <th>S.No</th>
                                <th>Item</th>
                                <th>Brand</th>
                                <th>Qty</th>
                            </tr>
                            <tr v-for="(data,idx) in stockReportData" :key="data.id">
                                <td>{{idx + 1}}</td>
                                <td>{{data.itemname}}</td>
                                <td>{{data.brand}}</td>
                                <td>{{data.qty}}</td>
                            </tr>
                            <tr>
                                <td colspan="3"><b> Total:</b></td>
                                <td>{{totalAmount}}</td>
                            </tr>



                            
                        </table>

                 
                        
                    
                    </div>   
              
                

                </div>
            </div>


        </main>
    </div>
</template>

<script>
export default {
    name: "StockReport",
    data(){
        return{
            fordate: '',
            totalAmount:0,
            token: '',
            stockReportData: [],
          

        }
    },
    mounted(){
        this.fordate = localStorage.getItem('ad');
        this.token = localStorage.getItem(this.$ut);
       
    },
    methods:{
        generateReport(){
            const params = JSON.stringify({
                reportname: 'stockreport',
                fordate : this.fordate
            })

            this.$confirm('Stock Report for ' + this.fordate, 'Confirm: ').then(() =>
                
                this.$df.post(this.$au + "reportdata",params,{headers : {"Authorization" : `Bearer ${this.token}`}}
                    ).then(res => {
            
                     if(res.data.data.length > 0){
                        this.stockReportData = res.data.data
               

                        var sum = 0;
                        this.stockReportData.forEach(e => {
                            sum += Number(e.qty);
                        });
                        
                        this.totalAmount = sum;

                    } else{
                        this.$alert("For " +  this.fordate , "No Data Found")
                    }    
                    
                    })
            )
        },
        exportReport(){
            // var element = document.getElementById('topdf')
            // html2pdf(element);
        },
        xlsReport(){
            // var element = document.getElementById('topdf')
            // tableToExcel('topdf', 'W3C Example Table')

        }

       
    }
}
</script>