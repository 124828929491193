<template>
    <div>
        <!-- screen loader -->
        <div class="container-fluid h-100 loader-display">
            <div class="row h-100">
                <div class="align-self-center col">
                    <div class="logo-loading">
                        <div class="icon icon-100 mb-4 rounded-circle">
                            <!-- <img src="img/favicon144.png" alt="" class="w-100"> -->
                            <span class="material-icons" style="font-size:100px;color:#ff0000">fitbit</span>
                        </div>
                        <h4 class="text-default">Daily Inventory</h4>
                        <p class="text-secondary">your daily book keeper</p>
                        <div class="loader-ellipsis">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Loader'
}

</script>