<template>
    <div>
        Backup Data
    </div>
</template>

<script>
export default {
    name: "BackupData",
    data(){
        return{
            activedate: '',

        }
    },
    mounted(){
        this.activedate = localStorage.getItem('ad');

    },
   
}
</script>