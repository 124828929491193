<template>
    <div>
        <!-- Button trigger modal -->
        <a href="javascript:void(0)" class="text-light" data-toggle="modal" data-target="#dateOpModal">  
            <span class="material-icons" style="font-size: 32px;">
                pending_actions
            </span>
        </a>

        <!-- Modal -->
        <div class="modal fade" id="dateOpModal" tabindex="-1" role="dialog" aria-labelledby="dateModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title text-primary" id="dateModalLabel">Select date to process Opening Balance</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <input type="date" placeholder="dd-mm-yyyy" class="form-control" v-model="activedate">


            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" @click="processOpening()">Process Opening</button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                
            </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'OpeningBalModal',
  data(){
      return{
          activedate:'',
          token:'',
      }
  },
   mounted(){
      this.activedate = localStorage.getItem('ad')
      this.token = localStorage.getItem(this.$ut)
      
  },
  methods:{
      processOpening(){

                const params = JSON.stringify({
                    activedate: this.activedate,
                });
          

              let loader = this.$loading.show({
                    // Optional parameters
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                    onCancel: this.onCancel,
                    color: '#f00',
                    loader: 'spinner', //spinner, bars, dots
                    width: 128,
                    height: 128,
                    opacity: .5,
                });


                
                
                this.$confirm('Are you sure you want to get the opening bal of previous day?','Confirm:').then(() =>
                    this.$df.post(this.$au + "processopening", params, { headers: {"Authorization" : `Bearer ${this.token}`}
                    }).then(res => {
                        if(res.data.status){

                            localStorage.setItem('ad',this.activedate)
                            this.$router.go('/')
                        }else{
                            this.$alert(res.data.message)
                        }

                        loader.hide()
                        
                    }).catch(err =>{
                        loader.hide()
                        console.log(err)
                    })
                )
          
      }

  },
 
  
}
</script>