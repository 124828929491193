<template>
    <div>
        <main class="flex-shrink-0 main has-footer">
           
            <div class="container h-100">
                <div class="card mb-4">
                    <div class="card-header">
                        <h6 class="subtitle mb-0">
                            <div class="avatar avatar-40 bg-default-light text-default rounded mr-2"><span class="material-icons vm">lock</span></div>
                             Generate Account Statement
                        </h6>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="form-group float-label col-12">

                            <select class="form-control" id="SelectAccType" v-model="selectedoption" >
                                <option value="" selected disabled>Select Account</option>
                                <option v-for="data in acData" :key="data.id" v-bind:value="{acid:data.id,acname:data.acname}">{{ data.acname }}</option>
                            </select>
                            
                        </div>

                        </div>
                        
                        <div class="row">
                        
                            <div class="form-group float-label active col-12" >
                                <label>For Date</label>
                                <input type="date" placeholder="dd-mm-yyyy" class="form-control" v-model="fordate">                           
                            </div>

                        
                        
                        </div>
                    </div>   
                    <div class="card-footer">
                        <div class="row">
                            <div class="col-6">
                                <button class="btn btn-block btn-success " @click="generateReport()">Generate Report</button>
                            </div>
                            
                            <div class="col-6">
                                <button class="btn btn-block btn-info " @click="generateSummary()">Generate Summary</button>
                            </div>
                        </div>
                       
                    </div>


                </div>
            </div>

               <div class="container h-100" v-if="saleReportData.length > 0">
                <div class="card mb-4">
                    <div class="card-header">
                        <div class="row p-3">
                            <div class="col-auto">
                            <h6 class="subtitle mb-0">
                            <div class="avatar avatar-40 bg-default-light text-default rounded mr-2"><span class="material-icons vm">lock</span></div>
                                Account Statement
                            </h6>
                        
                            </div>
                            <div class="col">&nbsp;</div>
                            <div class="col-auto">
                                <button class="btn btn-block btn-warning " @click="exportReport()">Export</button> 
                            </div>
                        </div>
                        
                    </div>
                    <div class="card-body">
                        
                       <table class="table table-striped table-bordered" id="topdf">
                           <tbody>
                           <tr><td colspan="10"><h4>Sales:</h4></td></tr>
                           <tr>
                               <th colspan="10"> A/c : {{selectedoption.acname}}</th>
                           </tr>
                           <tr>
                               <th colspan="10"> For : {{fordate}}</th>
                           </tr>                           
                            <tr>
                                <th>S.No</th>
                                <th>Date</th>
                                <th>Item</th>
                                <th>Brand</th>
                                <th>Qty</th>
                                <th>Rate</th>
                                <th>CGST</th>
                                <th>SGST</th>
                                <th>IGST</th>
                                <th>Total</th>
                            </tr>
                            
                            
                            <tr v-for="(data,idx) in saleReportData" :key="data.id">
                                <td>{{idx + 1}}</td>
                                <td>{{data.transdate}}</td>
                                <td>{{data.itemname}}</td>
                                <td>{{data.brand}}</td>
                                <td>{{data.qty}}</td>
                                <td>{{data.rate}}</td>
                                <td>{{data.cgst}}</td>
                                <td>{{data.sgst}}</td>
                                <td>{{data.igst}}</td>
                                <td>{{data.subtotal}}</td>
                            </tr>
                            <tr>
                                <td colspan="9"><b> Total:</b></td>
                                <td>{{totalAmount}}</td>
                            </tr>
                        

                        <tr><td colspan="10"><h4>Details:</h4></td></tr>
                      
                            <tr>
                                <th>S.No</th>
                                <th>Date</th>
                                <th colspan="2">Description</th>
                                <th colspan="3">Amount In</th>
                                <th colspan="3">Amount Out</th>
                               
                            </tr>
                            <tr v-for="(data,idx) in detailReportData" :key="data.id">
                                <td>{{idx + 1}}</td>
                                <td>{{data.transdate}}</td>
                                <td colspan="2">{{data.description}}</td>
                                <td colspan="3">{{data.amtin}}</td>
                                <td colspan="3">{{data.amtout}}</td>
                               
                            </tr>
                            <tr>
                                <td colspan="4"><b> Total:</b></td>
                                <td colspan="3">{{totalAmountIn}}</td>
                                <td colspan="3">{{totalAmountOut}}</td>
                            </tr>
                       

                         <tr><td colspan="10"><h4>Payments:</h4></td></tr>
                       
                            <tr v-for="(data) in paymentReportData" :key="data.id">
                                
                                <td>Opening: {{data.openingbal}}</td>
                                <td>Sale: {{data.saletotal}}</td>
                                <td colspan="2">Other Add: {{data.additions}}</td>
                                <td colspan="2">Other Ded: {{data.deductions}}</td>
                                <td colspan="2">Paid: {{data.amountpaid}}</td>
                                <td colspan="2">Net Payable: {{data.amountpayable}}</td>
                               
                            </tr>
                            </tbody>
                        </table>
    
                        

                    </div>   
              
                

                </div>
            </div>

            
           
        </main>
    </div>
</template>

<script>
export default {
    name: "SaleReport",
    data(){
        return{
            fordate: '',
            
            selectedoption:'',
            acData: [],
            saleReportData: [],
            paymentReportData: [],
            detailReportData: [],
            totalAmount:0,
            totalAmountIn: 0,
            totalAmountOut: 0,
            activedate: '',
            token: '',
           

        }
    },
    mounted(){
        this.activedate = localStorage.getItem('ad');
        this.token = localStorage.getItem(this.$ut);
        this.loadAccounts();
    },
    methods:{
        generateReport(){
            const params = JSON.stringify({
                reportname: 'accountreport',
                acid: this.selectedoption.acid,
                fordate : this.fordate,
                

            })

            if(!this.fordate){
                this.$alert('Please Select a date')
                return false;
            }



            this.$confirm('Generate Report for ' + this.fordate  ,'Confirm: ').then(() =>
                
                this.$df.post(this.$au + "reportdata",params,{headers : {"Authorization" : `Bearer ${this.token}`}}
                    ).then(res => {
                       
                    if(res.data.data.length > 0){
                        this.saleReportData = res.data.data
                        this.detailReportData = res.data.detaildata
                        this.paymentReportData = res.data.paymentdata

                        var sum = 0;
                        this.saleReportData.forEach(e => {
                            sum += Number(e.subtotal);
                        });
                        
                        this.totalAmount = sum;
                        
                        var totamtin =0;
                        var totamtout = 0;
                        this.detailReportData.forEach(e => {
                            totamtin += Number(e.amtin);
                            totamtout += Number(e.amtout);
                        })
                        this.totalAmountIn = totamtin;
                        this.totalAmountOut = totamtout;
                    

                    } else{
                        this.$alert("For " + this.selectedoption.acname + " on " + this.fordate , "No Data Found")
                    }
                    
                    
                    
                    })
            )
        },

        exportReport(){
            // var element = document.getElementById('topdf')
            // html2pdf(element);
        },
        xlsReport(){
            // var element = document.getElementById('topdf')
            // tableToExcel('topdf', 'W3C Example Table')

        },

        loadAccounts(){
            const params = JSON.stringify({
                reportname : 'accountreport',
            })
            this.$df.post(this.$au + "reportdatafetch",params,{headers : {"Authorization" : `Bearer ${this.token}`}}
            ).then(res =>{
                
                this.acData = res.data.data

            })
        }
    }
}
</script>